import React from 'react';
import PropTypes from 'prop-types';
import '../../../../style/views/parts/listitems/itemparts/PriceAddTestContentItem.css';
import MiniLoading from '../../../../misc/loading/MiniLoading';

const PriceAddTestContentItem = (props) => {
    const { service, onClick, busy } = props;
    return (
        <div className={'add-test-block'}>
            <span className={'add-test-price'}>{`$${service.fee}`}</span>
            <div className={`add-test-button${busy ? ' thinking' : ''}`} onClick={() => onClick(service.id)}>
                {busy ? <MiniLoading /> : 'Register'}
            </div>
        </div>
    );
};
PriceAddTestContentItem.propTypes = {
    service: PropTypes.object.isRequired,
    onClick: PropTypes.func.isRequired,
};
export default PriceAddTestContentItem;
