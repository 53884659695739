import React from 'react';
import SubTypeLoader from '../views/parts/loaders/SubTypeLoader';
import ServiceLoader from '../views/parts/loaders/ServiceLoader';
/*import VendorLoader from "../component/ftrc/serviceselector/listitems/loaders/VendorLoader";*/

export const appLocation = 'https://app.health-street.net/HealthStreetApp';

export const gtmId = 'GTM-TZVTXGP';

export const qpfHandicap = 7;
export const questHandicap = 2;
export const eScreenHandicap = 2.5;
export const tpHandicap = 10;

export const DEFAULT_PHYLA = [
    <SubTypeLoader key={1} />,
    <SubTypeLoader key={2} />,
    <SubTypeLoader key={3} />,
    <SubTypeLoader key={4} />,
    <SubTypeLoader key={5} />,
    <SubTypeLoader key={6} />,
    <SubTypeLoader key={7} />,
    <SubTypeLoader key={8} />,
    <SubTypeLoader key={9} />,
    <SubTypeLoader key={10} />,
    <SubTypeLoader key={11} />,
];

export const DEFAULT_SERVICES = [<ServiceLoader key={1} />, <ServiceLoader key={2} />, <ServiceLoader key={3} />];
/*

export const DEFAULT_VENDORS = [
	<VendorLoader key={1}/>,
	<VendorLoader key={2}/>,
	<VendorLoader key={3}/>,
	<VendorLoader key={4}/>,
	<VendorLoader key={5}/>,
	<VendorLoader key={6}/>,
	<VendorLoader key={7}/>,
	<VendorLoader key={8}/>,
	<VendorLoader key={9}/>,
	<VendorLoader key={10}/>,
];*/
