import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactHtmlParser from 'react-html-parser';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { withRouter } from 'react-router';
import '../../style/views/parts/VendorTile.css';

class VendorTile extends Component {
    constructor() {
        super();
        this.state = {
            class: 'pre-render',
        };
    }

    componentDidMount() {
        setTimeout(() => {
            this.setState({
                class: 'post-render',
            });
        }, 200);
    }

    registerNowClick = () => {
        const { click, vendor, history, updateHistory, error, setTargetVendor, focusTargetVendor } = this.props;
        window.location.href = 'https://app.health-street.net/order?vendorId=' + vendor.vendorId;
        return;
        if (!error) {
            const scrollToTop = () => {
                const c = document.documentElement.scrollTop || document.body.scrollTop;
                if (c > 0) {
                    window.requestAnimationFrame(scrollToTop);
                    window.scrollTo(0, c - c / 8);
                }
            };
            scrollToTop();
            setTargetVendor(vendor.vendorId);
            focusTargetVendor(vendor.vendorId);
            click(vendor);
        } else {
            const hist = '/clinic/error';
            updateHistory(hist);
            history.push(hist);
        }
    };

    render() {
        const { vendor, hover, hover2, clear, targetVendor, error, viewClick } = this.props;
        if (error) {
            return (
                <div
                    id="vendor-error"
                    className={`vendor-tile${'error' === targetVendor ? ' highlighted' : ''}${
                        ' ' + this.state.class
                    }`}
                    onMouseEnter={() => hover('error')}
                    onMouseLeave={() => hover(null)}>
                    <span className="vendor-name">No Clinics</span>
                    <hr />
                    <div className="address-section">
                        <div className="pointer-icon">
                            <FontAwesomeIcon icon="map-marker-alt" />
                        </div>
                        <div className="address-lines">
                            <span className="vendor-address-1">{vendor.errorMessage}</span>
                        </div>
                        <div className="register-button" onClick={this.registerNowClick}>
                            Register Now
                        </div>
                    </div>
                    <hr />
                    <div className="vendor-hours-box"></div>
                </div>
            );
        } else {
            return (
                <div
                    id={vendor.vendorId}
                    className={`vendor-tile${vendor.vendorId === targetVendor ? ' highlighted' : ''}${
                        ' ' + this.state.class
                    }`}
                    onMouseEnter={() => {
                        hover(vendor.vendorId);
                        hover2();
                    }}
                    onMouseLeave={() => {
                        hover(null);
                        clear();
                    }}>
                    <hr />
                    <div className="address-section">
                        <div className="pointer-icon">
                            <FontAwesomeIcon icon="map-marker-alt" />
                        </div>
                        <div className="address-lines">
                            <span className="vendor-address-1">{vendor.address}</span>
                            <span className="vendor-address-2">{vendor.address2}</span>
                            <span className="vendor-city-state-zip">
                                {vendor.city + ', ' + vendor.state + ' ' + vendor.zip}
                            </span>
                            <span
                                className="vendor-address-1 view-on-map"
                                onClick={() => {
                                    viewClick();
                                }}>
                                View on map
                            </span>
                        </div>
                        <div className="register-button" onClick={this.registerNowClick}>
                            Get Tested Here
                        </div>
                    </div>
                    <hr />
                    <div className="vendor-hours-box">{ReactHtmlParser(vendor.ftrcDays)}</div>
                </div>
            );
        }
    }
}

VendorTile.propTypes = {
    vendor: PropTypes.object,
    hover: PropTypes.func.isRequired,
    select: PropTypes.func.isRequired,
};
export default withRouter(VendorTile);
