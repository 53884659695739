import React from 'react';
import PropTypes from 'prop-types';

class CheckGroup extends React.Component {
    componentDidMount() {
        const { selectedValues, addFeatureOption, initFeature, feature } = this.props;
        if (selectedValues) {
            for (let i = 0; i < selectedValues.length; i++) {
                addFeatureOption(feature.id, selectedValues[i]);
            }
        } else {
            initFeature(feature.id);
        }
    }

    addOrRemoveOption = (e, service, feature, option) => {
        const { addFeatureOption, removeFeatureOption } = this.props;
        if (e.target.checked) {
            addFeatureOption(service, feature, option);
        } else {
            removeFeatureOption(service, feature, option);
        }
    };

    getChildContext() {
        const { name, selectedValue } = this.props;
        return {
            checkGroup: {
                name,
                selectedValue,
                onChange: this.addOrRemoveOption,
            },
        };
    }

    render() {
        const { Component, children } = this.props;
        return <Component>{children}</Component>;
    }
}

CheckGroup.defaultProps = {
    Component: 'div',
};

CheckGroup.propTypes = {
    name: PropTypes.string,
    selectedValues: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool, PropTypes.array]),
    onChange: PropTypes.func,
    children: PropTypes.node.isRequired,
    Component: PropTypes.oneOfType([PropTypes.string, PropTypes.func, PropTypes.object]),
};

CheckGroup.childContextTypes = {
    checkGroup: PropTypes.object,
};
export default CheckGroup;
