import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import VendorTile from '../VendorTile';
import {
    selectVendor,
    setTargetVendor,
    setMarkers,
    focusTargetVendor,
    hideSlideOut,
    hoverVendorTile,
    clearVendorTile,
    unfocusVendor,
} from '../../../store/actions';
import * as handoffHelper from '../../../util/HandoffHelper';
import '../../../style/views/parts/panels/VendorList.css';
import { updateHistory } from 'store/actions';

class VendorList extends Component {
    render() {
        const {
            history,
            interfaceState,
            vendors,
            setTargetVendor,
            focusTargetVendor,
            unfocusVendor,
            selectVendor,
            hoverVendorTile,
            clearVendorTile,
            order,
            hideSlideOut,
            updateHistory,
        } = this.props;
        const { showSlideOut, targetVendor, width } = interfaceState;
        let tiles = [];
        if (vendors && vendors.placeId && vendors[vendors.placeId].lastUpdated && showSlideOut) {
            if (vendors[vendors.placeId].errorMessage) {
                tiles = [].concat(
                    <VendorTile
                        key="error"
                        error={true}
                        hover={setTargetVendor}
                        vendor={{ errorMessage: vendors[vendors.placeId].errorMessage }}
                        targetVendor={targetVendor}
                        select={selectVendor}
                        order={order}
                    />,
                );
            } else {
                tiles = vendors[vendors.placeId].standard.map((vendor, index) => {
                    return (
                        <VendorTile
                            key={vendor.vendorId}
                            vendor={vendor}
                            hover={setTargetVendor}
                            hover2={hoverVendorTile}
                            clear={clearVendorTile}
                            viewClick={() => {
                                focusTargetVendor();
                                if (width < 750) {
                                    hideSlideOut();
                                }
                            }}
                            click={(vendor) => {
                                if (order.selectedService && order.selectedVendor && order.vendorInsufficient) {
                                    //we're selecting a new vendor after the one we picked didn't offer the service we wanted.
                                    order.selectedVendor = vendor;
                                    handoffHelper.handoffToFunnel(order);
                                } else {
                                    selectVendor(vendor);
                                    const hist = '/clinic/' + vendor.vendorId;
                                    updateHistory(hist);
                                    history.push(hist);
                                    unfocusVendor();
                                }
                            }}
                            unfocus={unfocusVendor}
                            renderOrder={index}
                            targetVendor={targetVendor}
                            select={selectVendor}
                            order={order}
                            setTargetVendor={setTargetVendor}
                            focusTargetVendor={focusTargetVendor}
                            updateHistory={updateHistory}
                        />
                    );
                });
            }
        }
        return (
            <div
                id="vendor-list"
                className={`vendor-list${order.selectedVendor && !order.vendorInsufficient ? ' noscroll' : ''}`}
            >
                {tiles}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        order: state.order,
        interfaceState: state.interface,
        vendors: state.vendors,
    };
};
const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            setTargetVendor: setTargetVendor,
            selectVendor: selectVendor,
            focusTargetVendor: focusTargetVendor,
            unfocusVendor: unfocusVendor,
            hoverVendorTile: hoverVendorTile,
            clearVendorTile: clearVendorTile,
            hideSlideOut: hideSlideOut,
            setMarkers: setMarkers,
            updateHistory: updateHistory,
        },
        dispatch,
    );
};
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(VendorList));
