import React from 'react';
import '../../../style/views/parts/loaders/ServiceLoader.css';

const ServiceLoader = () => {
    return (
        <div className="loading-masker">
            <div className={'web-cat-loader'}>Family Name</div>
            <div className="service-timeline-item">
                The name of a Service
                <div className="add-test-button">Register</div>
            </div>
            <div className="service-timeline-item">
                The name of a Service
                <div className="add-test-button">Register</div>
            </div>
            <div className="service-timeline-item">
                The name of a Service
                <div className="add-test-button">Register</div>
            </div>
            <div className="service-timeline-item">
                The name of a Service
                <div className="add-test-button">Register</div>
            </div>
            <div className="service-timeline-item">
                The name of a Service
                <div className="add-test-button">Register</div>
            </div>
        </div>
    );
};
export default ServiceLoader;
