import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { clearOrder, focusTargetVendor, selectVendor, setTargetVendor, showSlideOut } from '../store/actions';
import bluePin from './blue-pin2.png';
import redPin from './red-pin2.png';
import { withRouter } from 'react-router';
import VendorDetailsPopup from '../views/parts/VendorDetailsPopup';

class Marker extends Component {
    /*
	//the purpose of this component is to house the google marker object in props so that we can access it
	//for icon color changes and during the component lifecycle instead of storing it in state or redux
	//since it is not serializable (recursive).
	*/

    registerNowClick = () => {
        const { focusTargetVendor, setTargetVendor, vendorId } = this.props;
        setTargetVendor(vendorId, true);
        focusTargetVendor();
        this.setInfoWindowPinDetailContent();
    };

    hoverPinAction = () => {
        const { google, vendorId, selectedPin, setTargetVendor, expandSlideOut, width, scroll } = this.props;
        setTargetVendor(vendorId, true);
        this.marker.setIcon(this.ourBluePin);
        this.marker.setZIndex(google.maps.Marker.MAX_ZINDEX);
        this.marker.setAnimation(google.maps.Animation.BOUNCE);
        this.bounceTimeout = setTimeout(() => {
            this.marker.setAnimation(null);
        }, 700);
        //show slide out for scroll maybe
        if (selectedPin) {
            if (!showSlideOut && width > 750) {
                expandSlideOut();
                setTimeout(() => scroll(vendorId), 200);
            } else if (width > 750) {
                scroll(vendorId);
            }
        }
    };

    unhoverPinAction = () => {
        const { vendorId, focusedVendor, setTargetVendor } = this.props;
        setTargetVendor(null, true);
        if (focusedVendor !== vendorId) {
            this.marker.setIcon(this.ourRedPin);
        }
        this.marker.setZIndex(null);
        this.marker.setAnimation(null);
        clearTimeout(this.bounceTimeout);
    };

    renderMarker() {
        const { map, google, position, delay } = this.props;
        this.ourRedPin = {
            url: redPin,
            anchor: new google.maps.Point(13, 43),
        };
        this.ourBluePin = {
            url: bluePin,
            anchor: new google.maps.Point(13, 43),
        };
        this.timeout = setTimeout(() => {
            const latLng = new google.maps.LatLng(position.lat, position.lng);
            const pref = {
                map,
                position: latLng,
                animation: google.maps.Animation.DROP,
            };
            this.marker = new google.maps.Marker(pref);
            this.marker.addListener('mouseover', () => {
                // console.log('mouseover');
                this.hoverPinAction();
            });
            this.marker.addListener('mouseout', () => {
                this.unhoverPinAction();
            });
            this.marker.addListener('click', () => {
                this.registerNowClick();
            });
            this.marker.setIcon(this.ourRedPin);
        }, delay);
    }

    componentDidMount() {
        this.containerElement = document.createElement('div');
        const InfoWindow = require('./InfoWindow.js').default;
        this.infoWindow = new InfoWindow();
        this.renderMarker();
    }

    shouldComponentUpdate(nextProps) {
        const { targetVendor, focusedVendor, vendorId, map } = this.props;
        //if we're not dealing with this pin during the update, lets not update it.
        /*return (vendorId === focusedVendor || vendorId === nextProps.focusedVendor) || 
			(vendorId === targetVendor || vendorId === nextProps.targetVendor) ||
			(!map && nextProps.map);*/
        return true;
    }
    componentDidUpdate(prevProps) {
        const { map, targetVendor, focusedVendor, vendorId } = this.props;
        if (!prevProps.map && map) console.log('map appeared out of nowhere');
        if (this.marker) {
            if (focusedVendor !== vendorId && prevProps.focusedVendor === vendorId) {
                //targeted another vendor
                this.unhoverPinAction();
                this.clearInfoWindowPinDetailContent();
            }
            if (focusedVendor === vendorId && prevProps.focusedVendor !== vendorId) {
                //targeted this vendor
                this.hoverPinAction();
                this.registerNowClick();
            }
            if (targetVendor === vendorId && prevProps.targetVendor !== vendorId) {
                this.hoverPinAction();
            } else if (prevProps.targetVendor === vendorId && targetVendor !== vendorId) {
                this.unhoverPinAction();
            }
            if (!prevProps.map && map) {
                this.marker.setMap(map);
            }
        }
    }

    componentWillUnmount() {
        if (this.timeout) {
            //clear that timeout in case it hasn't run yet. should take care of markers that haven't been created.
            clearTimeout(this.timeout);
        }
        if (this.bounceTimeout) {
            clearTimeout(this.bounceTimeout);
        }
        if (this.marker) {
            //if the marker is already created, clear it
            this.marker.setMap(null);
            this.marker = null;
        }
    }

    render() {
        return null; //return null because markers don't actually render in DOM ouside of the google map
    }

    setInfoWindowPinDetailContent = () => {
        const { vendorId, vendors, map, history } = this.props;
        const vendor = vendors[vendors.placeId].itemsById[vendorId];
        ReactDOM.render(
            <VendorDetailsPopup history={history} store={this.context.store} vendor={vendor} />,
            this.containerElement,
        );
        this.infoWindow.setContent(this.containerElement);
        this.infoWindow.open(map, this.marker);
    };

    clearInfoWindowPinDetailContent = () => {
        ReactDOM.unmountComponentAtNode(this.containerElement);
        this.infoWindow.close();
    };
}

Marker.propTypes = {
    google: PropTypes.object,
    map: PropTypes.object,
    position: PropTypes.object,
    delay: PropTypes.number,
    scroll: PropTypes.func,
    vendorId: PropTypes.number,
    targetVendor: PropTypes.number,
    selectPin: PropTypes.func,
};

Marker.contextTypes = {
    store: PropTypes.object,
};

const mapStateToProps = (state) => {
    return {
        showSlideOut: state.interface.showSlideOut,
        targetVendor: state.interface.targetVendor,
        focusedVendor: state.interface.focusedVendor,
        selectedPin: state.map.selectedPin,
        width: state.interface.width,
        vendors: state.vendors,
        order: state.order,
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            setTargetVendor: setTargetVendor,
            focusTargetVendor: focusTargetVendor,
            select: selectVendor,
            clearOrder: clearOrder,
            expandSlideOut: showSlideOut,
        },
        dispatch,
    );
};
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Marker));
