import * as actionTypes from '../actionTypes';

const initialState = {
    history: null,
    ready: false,
    blurOnly: true,
    depth: 0,
    showSlideOut: false,
    aboutService: null,
    allVendorsRendered: false,
    showPreferredVendors: false,
    showStandardVendors: false,
    targetVendor: null,
    focusedVendor: null,
    lastFocusedVendor: null,
    hoverVendorTile: null,
    width: null,
    height: null,
    showExtendedSlideOut: false,
    slideoutHasExtendedOnce: false,
    fetchingAltService: false,
    isSearchInputPulsing: false,
    isSearchButtonPulsing: false,
};
export default (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.RESIZE_WINDOW:
            return {
                ...state,
                width: action.width,
                height: action.height,
            };
        case actionTypes.UPDATE_HISTORY:
            return {
                ...state,
                history: action.history,
            };
        case actionTypes.SET_READY:
            return {
                ...state,
                ready: true,
                blurOnly: false,
            };
        case actionTypes.SET_BLUR_ONLY:
            return {
                ...state,
                blurOnly: false,
            };
        case actionTypes.SET_NOT_READY:
            return {
                ...state,
                ready: false,
            };
        case actionTypes.SET_DEPTH:
            return {
                ...state,
                depth: action.depth,
            };
        case actionTypes.ABOUT_CLICK:
            return {
                ...state,
                aboutService: action.service,
            };
        case actionTypes.SHOW_SLIDE_OUT:
            return {
                ...state,
                showSlideOut: true,
            };
        case actionTypes.HIDE_SLIDE_OUT:
            return {
                ...state,
                showSlideOut: false,
                targetVendor: null,
            };
        case actionTypes.SET_TARGET_VENDOR:
            return {
                ...state,
                targetVendor: parseInt(action.vendorId, 10),
            };
        case actionTypes.FOCUS_TARGET_VENDOR:
            return {
                ...state,
                focusedVendor: state.targetVendor,
                lastFocusedVendor: state.targetVendor,
            };
        case actionTypes.UNFOCUS_VENDOR:
            return {
                ...state,
                focusedVendor: null,
            };
        case actionTypes.HOVER_VENDOR_TILE:
            return {
                ...state,
                hoverVendorTile: true,
            };
        case actionTypes.CLEAR_VENDOR_TILE:
            return {
                ...state,
                hoverVendorTile: null,
            };
        case actionTypes.SHOW_ALL_VENDORS:
            return {
                ...state,
                showPreferredVendors: true,
                showStandardVendors: true,
            };
        case actionTypes.SHOW_PREFERRED_VENDORS:
            return {
                ...state,
                showPreferredVendors: true,
            };
        case actionTypes.SLIDE_OUT_EXTEND:
            return {
                ...state,
                showExtendedSlideOut: true,
                slideoutHasExtendedOnce: true,
            };
        case actionTypes.SLIDE_OUT_RETRACT:
            return {
                ...state,
                showExtendedSlideOut: false,
            };
        case actionTypes.START_ALT_SERVICE_FETCH:
            return {
                ...state,
                fetchingAltService: true,
            };
        case actionTypes.STOP_ALT_SERVICE_FETCH:
            return {
                ...state,
                fetchingAltService: false,
            };
        case actionTypes.SET_SEARCH_INPUT_PULSE:
            return {
                ...state,
                isSearchInputPulsing: action.isInputPulsing,
            };
        case actionTypes.SET_SEARCH_BUTTON_PULSE:
            return {
                ...state,
                isSearchButtonPulsing: action.isButtonPulsing,
            };
        default:
            return state;
    }
};
