import React from 'react';
import PropTypes from 'prop-types';
import '../../../../style/views/parts/listitems/itemparts/ServiceDetailsComponent.css';

const ServiceDetailsComponent = (props) => {
    const { id, click } = props;

    return (
        <div
            className={'detail-icon'}
            onClick={(e) => {
                e.stopPropagation();
                click(id);
            }}
        >
            About
        </div>
    );
};
ServiceDetailsComponent.propTypes = {
    id: PropTypes.number.isRequired,
    click: PropTypes.func.isRequired,
    details: PropTypes.string,
};
export default ServiceDetailsComponent;
