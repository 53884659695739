import * as actionTypes from '../actionTypes';

export default function (state = {}, action) {
    switch (action.type) {
        case actionTypes.SHOW_MODIFIER_DRAWER:
            let newOptions = {};
            action.features.forEach((feature) => {
                feature.options.forEach((option) => {
                    newOptions[option.id] = option;
                });
            });
            return Object.assign({}, state, newOptions);
        case actionTypes.CLEAR_SELECTED_SERVICE:
            return Object.assign({}, state);
        default:
            return state;
    }
}
