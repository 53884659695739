import React, { Component } from 'react';
import '../style/views/ModifierDrawer.css';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
    addFeatureOption,
    clearSelectedService,
    confirmFeatures,
    hideModifierDrawer,
    initFeature,
    removeFeatureOption,
    updateFeatureOption,
} from '../store/actions';
import Radio from './parts/form/Radio';
import Check from './parts/form/Check';
import RadioGroup from './parts/form/RadioGroup';
import CheckGroup from './parts/form/CheckGroup';
import MiniLoading from '../misc/loading/MiniLoading';

class ModifierDrawer extends Component {
    render() {
        const {
            order,
            initFeature,
            updateFeatureOption,
            addFeatureOption,
            removeFeatureOption,
            hideModifierDrawer,
            location,
            history,
            clearSelectedService,
            confirmFeatures,
            fetchingAltService,
        } = this.props;
        const selectedFeatures = order.selectedFeatures;
        if (!order.selectedService) {
            return null; //not loaded yet, but not loading from api so fuck a loader
        }
        const featureSection = order.features.map((feature) => {
            let defaultOption = null;
            let options = feature.options.map((option) => {
                let featureLabel =
                    option.fee && option.fee > 0
                        ? option.name + ' (+$' + option.fee.toString() + ')'
                        : option.name;
                if (feature.type === 'Radio') {
                    if (option.defaultRadio === 1) defaultOption = option.id;
                    return (
                        <Radio
                            key={option.id}
                            value={option.id}
                            feature={feature.id}
                            service={order.selectedService.id}
                            label={featureLabel}
                        />
                    );
                } else if (feature.type === 'Checkbox') {
                    let selected =
                        selectedFeatures[order.selectedService.id] &&
                        selectedFeatures[order.selectedService.id][feature.id]
                            ? !!(selectedFeatures[order.selectedService.id][feature.id].indexOf(option.id) !== -1)
                            : false;
                    return (
                        <Check
                            key={option.id}
                            value={option.id}
                            feature={feature.id}
                            service={order.selectedService.id}
                            label={featureLabel}
                            checked={selected}
                        />
                    );
                } else {
                    return null;
                }
            });
            const selectedOption =
                feature.type === 'Radio' && selectedFeatures && selectedFeatures[order.selectedService.id]
                    ? selectedFeatures[order.selectedService.id][feature.id]
                        ? selectedFeatures[order.selectedService.id][feature.id][0]
                        : defaultOption
                    : defaultOption;
            const selectedOptions =
                feature.type === 'Checkbox' && selectedFeatures && selectedFeatures[order.selectedService.id]
                    ? selectedFeatures[order.selectedService.id][feature.id]
                        ? selectedFeatures[order.selectedService.id][feature.id]
                        : defaultOption
                    : defaultOption;
            //console.log('selected options', selectedOptions);
            let featureControl =
                feature.type === 'Radio' ? (
                    <RadioGroup
                        key={order.selectedService.id.toString() + feature.id.toString()}
                        name={feature.name}
                        selectedValue={selectedOption}
                        onChange={updateFeatureOption}
                        service={order.selectedService.id}
                        feature={feature}
                    >
                        {options}
                    </RadioGroup>
                ) : feature.type === 'Checkbox' ? (
                    <CheckGroup
                        name={feature.name}
                        selectedValues={selectedOptions}
                        addFeatureOption={addFeatureOption}
                        initFeature={initFeature}
                        feature={feature}
                        removeFeatureOption={removeFeatureOption}
                        service={order.selectedService.id}
                    >
                        {options}
                    </CheckGroup>
                ) : (
                    ''
                );
            return (
                <div key={feature.id} className={'modifier-feature'}>
                    <div className={'feature-name'}>{feature.name}</div>
                    <div className={'feature-description'}>{feature.description}</div>
                    <div className={'feature-control'}>{featureControl}</div>
                </div>
            );
        });

        return (
            <div className="modifier-drawer-container">
                <div className="modifier-drawer">
                    <div className={'modifier-header'}>
                        <div className={'modifier-title'}>Extra Options</div>
                        <div
                            className={'modifier-closer'}
                            onClick={() => {
                                setTimeout(() => {
                                    hideModifierDrawer();
                                    clearSelectedService();
                                }, 700);
                                history.goBack();
                            }}
                        >
                            Close
                        </div>
                    </div>
                    <div className={'modifier-body'}>{featureSection}</div>
                    <div className="modifier-footer">
                        {fetchingAltService ? (
                            <MiniLoading style={{ height: '36px', paddingTop: '6px' }} />
                        ) : (
                            <div
                                className="modifier-confirm"
                                onClick={() => confirmFeatures(order.selectedService, location, history)}
                            >
                                Register
                            </div>
                        )}

                        <div
                            className="modifier-cancel"
                            onClick={() => {
                                setTimeout(() => {
                                    hideModifierDrawer();
                                    clearSelectedService();
                                }, 700);
                                history.goBack();
                            }}
                        >
                            Go Back
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        order: state.order,
        fetchingAltService: state.interface.fetchingAltService,
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            initFeature: initFeature,
            updateFeatureOption: updateFeatureOption,
            addFeatureOption: addFeatureOption,
            removeFeatureOption: removeFeatureOption,
            hideModifierDrawer: hideModifierDrawer,
            clearSelectedService: clearSelectedService,
            confirmFeatures: confirmFeatures,
        },
        dispatch,
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(ModifierDrawer);
