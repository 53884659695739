import * as actionTypes from '../actionTypes';

const kingdoms = (
    state = {
        isFetching: false,
        didInvalidate: true,
        items: [],
    },
    action,
) => {
    switch (action.type) {
        case actionTypes.REQUEST_KINGDOMS:
            return Object.assign({}, state, {
                isFetching: true,
                didInvalidate: false,
            });
        case actionTypes.RECEIVE_KINGDOMS:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: false,
                items: action.kingdoms,
                lastUpdated: action.receivedAt,
            });
        default:
            return state;
    }
};

export default function (state = {}, action) {
    switch (action.type) {
        case actionTypes.RECEIVE_KINGDOMS:
        case actionTypes.REQUEST_KINGDOMS:
            return Object.assign({}, state, kingdoms(state[action.kingdomId], action));
        default:
            return state;
    }
}
