import React, { Component } from 'react';
import '../../style/loading/NotReadyOverlay.css';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Loading from './Loading';
import { setReady } from '../../store/actions';

class NotReadyOverlay extends Component {
    componentDidUpdate(prevProps, prevState) {
        const { setReady, vendors } = this.props;
        if (vendors.placeId && prevProps.vendors.placeId) {
            if (
                vendors[vendors.placeId].lastUpdated &&
                vendors[vendors.placeId].lastUpdated !== prevProps.vendors[prevProps.vendors.placeId].lastUpdated
            ) {
                setReady();
            }
        }
    }

    render() {
        const { ready, children, blurOnly } = this.props;
        return (
            <div className="not-ready-container">
                <div className={`not-ready-overlay${ready ? ' ready' : ' not-ready'}`}>{children}</div>
                {!ready && !blurOnly ? <Loading /> : ''}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        ready: state.interface.ready,
        blurOnly: state.interface.blurOnly,
        allVendorsRendered: state.interface.allVendorsRendered,
        showSlideOut: state.interface.showSlideOut,
        vendors: state.vendors,
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            setReady: setReady,
        },
        dispatch,
    );
};
export default connect(mapStateToProps, mapDispatchToProps)(NotReadyOverlay);
