import React from 'react';
import '../../style/loading/MiniLoading.css';

const MiniLoading = (props) => {
    const { style } = props;
    return (
        <div className="lds-css ng-scope" style={style}>
            <div style={{ width: '100%', height: '100%' }} className="mini-lds-double-ring">
                <div></div>
                <div></div>
            </div>
        </div>
    );
};
export default MiniLoading;
