class LatLng {
    constructor(lat, lng) {
        if (typeof lng === 'undefined') {
            //lat is actually a latLng literal
            this.latVal = lat.lat;
            this.lngVal = lat.lng;
        } else {
            this.latVal = lat;
            this.lngVal = lng;
        }
    }

    lat() {
        return this.latVal;
    }

    lng() {
        return this.lngVal;
    }
}
export default LatLng;
