import * as actionTypes from '../actionTypes';

const initialState = {
    center: null,
    markers: null,
    selectedPin: null,
    zip: null,
    placeId: null,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case actionTypes.SET_MAP_CENTER:
            return {
                ...state,
                center: {
                    lat: action.center.lat(),
                    lng: action.center.lng(),
                },
                placeId: action.placeId,
            };
        case actionTypes.SET_MARKERS:
            return {
                ...state,
                markers: action.markers,
            };
        case actionTypes.REMOVE_MARKERS:
            return {
                ...state,
                markers: null,
            };
        case actionTypes.SET_TARGET_VENDOR:
            return {
                ...state,
                selectedPin: action.vendorId == null || !action.isPin ? null : action.vendorId,
            };
        case actionTypes.CLEAR_MAP:
            return {
                ...state,
                markers: null,
            };
        default:
            return state;
    }
}
