class LatLngBounds {
    constructor(sw, ne) {
        this.sw = sw;
        this.ne = ne;
    }

    getNorthEast() {
        return this.ne;
    }

    getSouthWest() {
        return this.sw;
    }
}
export default LatLngBounds;
