import * as actionTypes from '../actionTypes';

const subTypes = (
    state = {
        isFetching: false,
        didInvalidate: true,
        items: [],
    },
    action,
) => {
    switch (action.type) {
        case actionTypes.REQUEST_PHYLA:
            return Object.assign({}, state, {
                isFetching: true,
                didInvalidate: false,
            });
        case actionTypes.RECEIVE_PHYLA:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: false,
                items: action.phyla,
                lastUpdated: action.receivedAt,
            });
        default:
            return state;
    }
};

export default function (state = {}, action) {
    switch (action.type) {
        case actionTypes.RECEIVE_PHYLA:
            return Object.assign({}, state, {
                [action.kingdomId]: subTypes(state[action.kingdomId], action),
                received: true,
            });
        case actionTypes.REQUEST_PHYLA:
            return Object.assign({}, state, {
                [action.kingdomId]: subTypes(state[action.kingdomId], action),
            });
        default:
            return state;
    }
}
