import React from 'react';
import PropTypes from 'prop-types';

class Radio extends React.Component {
    render() {
        const { name, selectedValue, onChange } = this.context.radioGroup;
        const optional = {};
        if (selectedValue !== undefined) {
            optional.checked = this.props.value === selectedValue;
        }
        if (typeof onChange === 'function') {
            optional.onChange = onChange.bind(null, this.props.feature, [this.props.value]);
        }

        return (
            <div className={'radio-control'}>
                <input {...this.props} aria-checked={optional.checked} type="radio" name={name} {...optional} />
                <div className={'radio-label'}>{this.props.label}</div>
            </div>
        );
    }
}

Radio.contextTypes = {
    radioGroup: PropTypes.object,
};
export default Radio;
