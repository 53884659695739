import React from 'react';
import PropTypes from 'prop-types';
import '../../../style/views/parts/listitems/ListItem.css';

const PhylumListItem = (props) => {
    const { phylumId, customClass, expanded, onClick, name } = props;

    return (
        <div
            className={`list-item${customClass != null ? ' ' + customClass : ''}${expanded ? ' expanded' : ''}`}
            onClick={(e) => {
                e.stopPropagation();
                onClick(phylumId);
            }}
        >
            <div className="item-left-justified">{name}</div>
            <div className={'item-right-justified'}>&gt;</div>
        </div>
    );
};
PhylumListItem.propTypes = {
    phylumId: PropTypes.number,
    customClass: PropTypes.string,
    expanded: PropTypes.bool,
    onClick: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    detailClick: PropTypes.func,
    details: PropTypes.string,
};
export default PhylumListItem;
