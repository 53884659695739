import React from 'react';
import PropTypes from 'prop-types';

class Check extends React.Component {
    render() {
        const { name, onChange } = this.context.checkGroup;

        return (
            <div className={'check-control'}>
                <input
                    {...this.props}
                    aria-checked={this.props.checked}
                    type="checkbox"
                    name={name}
                    onChange={(e) => {
                        onChange(e, this.props.feature, this.props.value);
                    }}
                />
                <div className={'check-label'}>{this.props.label}</div>
            </div>
        );
    }
}

Check.contextTypes = {
    checkGroup: PropTypes.object,
};
export default Check;
