import React, { Component } from 'react';
import '../../style/views/parts/SlidingFamilyPanel.css';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { aboutClick, clearSelectedPhylum, fetchFamilies, validateSelection } from '../../store/actions';
import * as constants from '../../misc/Constants';
import ServiceListItem from './listitems/ServiceListItem';
import FamilyGroupListItem from './listitems/FamilyGroupListItem';
import { CSSTransition } from 'react-transition-group';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { updateHistory } from 'store/actions';

class SlidingFamilyPanel extends Component {
    componentDidMount() {
        const { phylum, fetchFamilies } = this.props;
        fetchFamilies(phylum);
    }

    render() {
        const {
            location,
            history,
            updateHistory,
            kingdom,
            phylum,
            kingdomsById,
            phylaById,
            families,
            aboutClick,
            selectedVendor,
            selectedService,
            validateSelection,
            clearSelectedPhylum,
            order,
        } = this.props;
        const familySet = families[phylum];
        const unloaded = !familySet;
        const loading = familySet && familySet.isFetching;
        const fetched = familySet && !familySet.isFetching && !familySet.didInvalidate;
        let panelContent;
        if (loading || unloaded) {
            panelContent = constants.DEFAULT_SERVICES;
        } else if (fetched) {
            panelContent = []
                .concat(Object.keys(familySet.items))
                .sort((a, b) => {
                    let aSort = familySet.items[a].sortOrder ? familySet.items[a].sortOrder : 99;
                    let bSort = familySet.items[b].sortOrder ? familySet.items[b].sortOrder : 99;
                    return aSort - bSort;
                })
                .map((family) => {
                    let familyServices = [];
                    if (familySet.items[family].services) {
                        familyServices = []
                            .concat(Object.keys(familySet.items[family].services))
                            .sort((a, b) => {
                                let aSort = familySet.items[family].services[a].sortOrder
                                    ? familySet.items[family].services[a].sortOrder
                                    : 99;
                                let bSort = familySet.items[family].services[b].sortOrder
                                    ? familySet.items[family].services[b].sortOrder
                                    : 99;
                                return aSort - bSort;
                            })
                            .map((id) => {
                                const service = familySet.items[family].services[id];
                                return (
                                    <ServiceListItem
                                        key={service.id}
                                        service={service}
                                        onClick={() => {
                                            validateSelection(service, location, history);
                                        }}
                                        detailClick={() => {
                                            aboutClick(service);
                                            const hist = history.location.pathname + '/modal';
                                            updateHistory(hist);
                                            history.push(hist);
                                        }}
                                        query={/*query*/ ''}
                                        busy={selectedService ? selectedService.id === service.id : false}
                                    />
                                );
                            });
                    }
                    return (
                        <FamilyGroupListItem
                            key={familySet.items[family].id}
                            name={familySet.items[family].name}
                            services={familyServices}
                        />
                    );
                });
        }
        return (
            <div className="family-panel-container">
                <div
                    className={`family-panel-title${order.kingdomSelectedFromDocument ? ' skipped-kingdom' : ''}`}
                >
                    {phylaById[phylum].wPhylumHeader}
                </div>
                <div
                    className={`family-panel-back-button${order.kingdomSelectedFromDocument ? ' skipped-kingdom' : ''}`}
                    onClick={(e) => {
                        e.stopPropagation();
                        clearSelectedPhylum();
                        const hist = '/clinic/' + selectedVendor.vendorId + '/' + kingdom;
                        updateHistory(hist);
                        history.push(hist);
                    }}
                >
                    <FontAwesomeIcon className="back-button-left-arrow" icon="angle-left" />
                    {kingdomsById[kingdom].shortName}
                </div>
                <CSSTransition timeout={200} classNames="blur" in={fetched}>
                    <div
                        className={`family-panel${order.kingdomSelectedFromDocument ? ' skipped-kingdom' : ''}`}
                        onClick={(e) => {
                            e.stopPropagation();
                        }}
                    >
                        {panelContent}
                    </div>
                </CSSTransition>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        kingdomsById: state.kingdomsById,
        phylaById: state.phylaById,
        families: state.families,
        selectedService: state.order.selectedService,
        vendorConfirmed: state.order.vendorConfirmed,
        selectedVendor: state.order.selectedVendor,
        order: state.order,
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            fetchFamilies: fetchFamilies,
            aboutClick: aboutClick,
            validateSelection: validateSelection,
            clearSelectedPhylum: clearSelectedPhylum,
            updateHistory: updateHistory,
        },
        dispatch,
    );
};
export default connect(mapStateToProps, mapDispatchToProps)(SlidingFamilyPanel);
