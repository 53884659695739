import * as actionTypes from '../actionTypes';

const vendors = (
    state = {
        errorMessage: null,
        isFetching: false,
        didInvalidate: true,
        standard: [],
    },
    action,
) => {
    switch (action.type) {
        case actionTypes.REQUEST_VENDORS:
            return Object.assign({}, state, {
                isFetching: true,
                didInvalidate: false,
            });
        case actionTypes.RECEIVE_VENDORS:
            let vendorsById = action.vendors.reduce((acc, cur) => {
                acc[cur.vendorId] = cur;
                return acc;
            }, {});
            if (action.vendors[0] && action.vendors[0].errorMessage) {
                return Object.assign({}, state, {
                    isFetching: false,
                    didInvalidate: false,
                    standard: [],
                    errorMessage: action.vendors[0].errorMessage,
                    lastUpdated: action.receivedAt,
                });
            }
            return Object.assign({}, state, {
                errorMessage: null,
                isFetching: false,
                didInvalidate: false,
                standard: action.standard,
                itemsById: vendorsById,
                lastUpdated: action.receivedAt,
            });
        default:
            return state;
    }
};

export default function (state = {}, action) {
    switch (action.type) {
        case actionTypes.RECEIVE_VENDORS:
        case actionTypes.REQUEST_VENDORS:
            return Object.assign({}, state, {
                [action.placeId]: vendors(state[action.placeId], action),
                placeId: action.placeId,
                centermostVendor: action.centermostVendor,
            });
        case actionTypes.CLEAR_MAP:
            return {};
        default:
            return state;
    }
}
