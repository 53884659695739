import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import KingdomButton from '../KingdomButton';
import { clearSelectedKingdom, selectKingdom } from '../../../store/actions';
import '../../../style/views/parts/panels/KingdomList.css';
import { updateHistory } from 'store/actions';

class KingdomList extends Component {
    kingdomButtonClick = (kingdomId) => {
        const { order, history, updateHistory, selectKingdom } = this.props;
        selectKingdom(kingdomId);
        const hist = '/clinic/' + order.selectedVendor.vendorId + '/' + kingdomId;
        updateHistory(hist);
        history.push(hist);
    };

    render() {
        const { kingdoms, selectedKingdom, kingdomSelectedFromDocument } = this.props;
        const buttons = kingdoms.items.map((kingdom) => {
            const active = selectedKingdom === kingdom.id;
            return (
                <KingdomButton
                    key={kingdom.id}
                    kingdom={kingdom}
                    active={active}
                    onClick={() => this.kingdomButtonClick(kingdom.id)}
                />
            );
        });
        return (
            <div className="kingdom-list">
                <div className="kingdom-greeting-container">
                    <span className={`kingdom-greeting${selectedKingdom ? ' gone' : ''}`}>
                        What kind of service are you looking for today?
                    </span>
                </div>
                {selectedKingdom && kingdomSelectedFromDocument ? (
                    ''
                ) : (
                    <div className="kingdom-buttons-container">
                        <div
                            className={`kingdom-buttons${selectedKingdom ? ' elevated' : ''}`}
                            onClick={(e) => {
                                e.stopPropagation();
                            }}
                        >
                            {buttons}
                        </div>
                    </div>
                )}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        order: state.order,
        kingdoms: state.kingdoms,
        selectedKingdom: state.order.selectedKingdom,
        kingdomSelectedFromDocument: state.order.kingdomSelectedFromDocument,
        selectedVendor: state.order.selectedVendor,
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            selectKingdom: selectKingdom,
            clearSelectedKingdom: clearSelectedKingdom,
            updateHistory: updateHistory,
        },
        dispatch,
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(KingdomList);
