import * as actionTypes from '../actionTypes';

export default function (state = {}, action) {
    switch (action.type) {
        case actionTypes.RECEIVE_PHYLA:
            let newPhyla = action.phyla.reduce((acc, cur, i) => {
                acc[cur.id] = cur;
                return acc;
            }, {});
            return Object.assign({}, state, newPhyla);

        default:
            return state;
    }
}
