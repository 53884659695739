import React from 'react';

import cache from '../ScriptCache';
import GoogleApi from './GoogleApi';

export const wrapper = (options) => (WrappedComponent) => {
    const apiKey = options.apiKey;
    const libraries = options.libraries || ['places', 'geometry'];

    class Wrapper extends React.Component {
        constructor(props, context) {
            super(props, context);

            this.state = {
                loaded: false,
                map: null,
                google: null,
            };
        }

        componentDidMount() {
            this.scriptCache.google.onLoad((err, tag) => {
                this.setState({
                    loaded: true,
                    google: window.google,
                });
            });
        }

        componentWillMount() {
            this.scriptCache = cache({
                google: GoogleApi({
                    apiKey: apiKey,
                    libraries: libraries,
                }),
            });
        }

        render() {
            const props = Object.assign({}, this.props, {
                loaded: this.state.loaded,
                map: this.state.map,
                google: this.state.google,
                mapComponent: this.refs.map,
            });
            return (
                <div>
                    <WrappedComponent {...props} />
                </div>
            );
        }
    }

    return Wrapper;
};

export default wrapper;
