import 'babel-polyfill';
import React from 'react';
import ReactDOM from 'react-dom';
import LocationSelector from './views/LocationSelector';
import * as serviceWorker from './serviceWorker';
import { MemoryRouter as Router } from 'react-router-dom';
import { applyMiddleware, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import rootReducer from './store/reducers/CombinedReducers';
import { Provider } from 'react-redux';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
    faAngleLeft,
    faMapMarkerAlt,
    faStar,
    faAngleDoubleRight,
    faExclamationCircle,
    faSearch,
} from '@fortawesome/free-solid-svg-icons';
import { loadState, saveState } from 'util/LocalStorage';

//changed
const enhancers = compose(
    applyMiddleware(thunk),
    window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : (f) => f,
);
const visitedFunnel = document.cookie.indexOf('visited-hs-funnel') > -1;
const persistedState = visitedFunnel ? loadState() : undefined;
const persistedRoute = persistedState && visitedFunnel ? persistedState.interface.history : null;

const store = createStore(rootReducer, persistedState, enhancers);
store.subscribe(() => {
    saveState({
        ...store.getState(),
        order: {
            ...store.getState().order,
            selectedService: null,
        },
    });
});

//fontawesome icons
library.add(faAngleLeft);
library.add(faMapMarkerAlt);
library.add(faStar);
library.add(faAngleDoubleRight);
library.add(faExclamationCircle);
library.add(faSearch);

ReactDOM.render(
    <Provider store={store}>
        <Router hashType="noslash">
            <LocationSelector persistedRoute={persistedRoute} />
        </Router>
    </Provider>,
    document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
