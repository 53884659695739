import { combineReducers } from 'redux';
import InterfaceReducer from './InterfaceReducer';
import VendorReducer from './VendorReducer';
import MapReducer from './MapReducer';
import OrderReducer from './OrderReducer';
import KingdomReducer from './KingdomReducer';
import KingdomsByIdReducer from './KingdomsByIdReducer';
import PhylumReducer from './PhylumReducer';
import PhylumByIdReducer from './PhylumByIdReducer';
import FamilyReducer from './FamilyReducer';
import FamilyByIdReducer from './FamilyByIdReducer';
import FeaturesByIdReducer from './FeaturesByIdReducer';
import OptionsByIdReducer from './OptionsByIdReducer';
import LocationLookupReducer from './LocationLookupReducer';

const rootReducer = combineReducers({
    interface: InterfaceReducer,
    vendors: VendorReducer,
    map: MapReducer,
    kingdoms: KingdomReducer,
    kingdomsById: KingdomsByIdReducer,
    phyla: PhylumReducer,
    phylaById: PhylumByIdReducer,
    families: FamilyReducer,
    familiesById: FamilyByIdReducer,
    order: OrderReducer,
    featuresById: FeaturesByIdReducer,
    optionsById: OptionsByIdReducer,
    locationLookup: LocationLookupReducer,
});
export default rootReducer;
