import React from 'react';
import imgTruck from '../assets/images/truck-icon.png';
import imgCloud1 from '../assets/images/cloud-icon1.png';
import imgPlane from '../assets/images/plane-icon.png';
import imgGround from '../assets/images/ground.png';
import imgCity from '../assets/images/city.png';
import imgMountains1 from '../assets/images/mountains1.png';
import imgMountains2 from '../assets/images/mountains2.png';
import '../style/views/LocationSelector.css';

const LocationHero = (props) => (
    // FTRC HERO
    <div className="location-hero">
        <div className="ground">
            <img src={imgGround} alt={'Ground'} />
        </div>
        <div className="location-hero-wrapper">
            <div className="slogan">
                <h2>{document.slogan ? document.slogan : 'Click. Screen. Go.'}</h2>
                <h2 style={{ fontSize: '20px' }}>
                    {document.subline ? document.subline : 'Drug testing locations, everywhere.'}
                </h2>
            </div>
            <div className="icon cloud c1 mediumicon">
                <img src={imgCloud1} alt={'Medium cloud'} />
            </div>
            <div className="icon cloud c2 largeicon">
                <img src={imgCloud1} alt={'largeicon cloud'} />
            </div>
            <div className="icon cloud c3 smallicon">
                <img src={imgCloud1} alt={'smallicon cloud'} />
            </div>
            <div className="icon cloud c4 smallicon">
                <img src={imgCloud1} alt={'smallicon cloud'} />
            </div>
            <div className="truck">
                <img src={imgTruck} alt={'Truck'} />
            </div>
            <div className="icon plane smallicon">
                <img src={imgPlane} alt={'smallicon plane'} />
            </div>
            <div className="city">
                <img src={imgCity} alt={'City buildings'} />
            </div>
            <div className="mountains m1">
                <img src={imgMountains1} alt={'Mountains'} />
            </div>
            {props.width > 750 ? (
                <div className="mountains m2">
                    <img src={imgMountains2} alt={'Mountains'} />
                </div>
            ) : (
                ''
            )}
        </div>
    </div>
);
export default LocationHero;
