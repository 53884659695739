import React from 'react';
import PropTypes from 'prop-types';
import '../../../../style/views/parts/listitems/itemparts/ServiceDetailContentItem.css';
import ServiceDetailsComponent from './ServiceDetailsComponent';

const ServiceDetailContentItem = (props) => {
    const { id, name, detailClick, details } = props;
    return (
        <div className={'service-detail-block'}>
            <div className={'service-name'}>
                {name}
                <span
                    className="mobile-about"
                    onClick={(e) => {
                        e.stopPropagation();
                        detailClick(id);
                    }}
                >
                    About
                </span>
            </div>
            <ServiceDetailsComponent id={id} click={detailClick} details={details} />
        </div>
    );
};
ServiceDetailContentItem.propTypes = {
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    detailClick: PropTypes.func.isRequired,
    details: PropTypes.string,
};
export default ServiceDetailContentItem;
