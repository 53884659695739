import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { withRouter } from 'react-router';
import { Route, Switch } from 'react-router-dom';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import _ from 'lodash';
import queryString from 'querystring';
import GoogleApiComponent from '../util/google/GoogleApiComponent';
import MapContainer from '../map/MapContainer';
import {
    doLocationSearch,
    fetchKingdoms,
    fetchLocationCanonicalNameFromId,
    fetchLocationLatLngFromCanonicalName,
    fetchVendorsWithoutService,
    hideLocErrorMsg,
    hideSlideOut,
    resizeWindow,
    setMapCenter,
    showLocErrorMsg,
    showSlideOut,
    slideOutExtend,
    slideOutRetract,
    unfocusVendor,
    setSearchInputPulsing,
    setSearchButtonPulsing,
    setBlurOnly,
} from '../store/actions';
import NotReadyOverlay from '../misc/loading/NotReadyOverlay';
import VendorList from './parts/panels/VendorList';
import SlideOutContainer from './parts/SlideOutContainer';
import ServiceSelector from './parts/ServiceSelector';
import ModalDialog from './ModalDialog';
import ModifierDrawer from './ModifierDrawer';
import ServiceSpecificSearchDialog from './ServiceSpecificSearchDialog';
import * as geoUtils from '../misc/geo/GeoUtils';
import LatLng from '../misc/geo/LatLng';
import Header from './parts/Header';
import LocationHero from './LocationHero';
import loadingIcon from '../assets/images/loading.svg';
import '../style/views/LocationSelector.css';
import { updateHistory } from 'store/actions';

class LocationSelector extends Component {
    constructor(props) {
        super(props);
        const { persistedRoute, updateHistory, history } = props;
        if (persistedRoute && persistedRoute != 'hero') {
            updateHistory(persistedRoute);
            history.push(persistedRoute);
            this.isMap = true;
        }
    }

    componentDidMount() {
        const {
            match,
            history,
            updateHistory,
            fetchKingdoms,
            setMapCenter,
            fetchVendorsWithoutService,
            persistedRoute,
            interfaceState,
            setSearchInputPulsing,
            setSearchButtonPulsing,
        } = this.props;
        if (!persistedRoute || persistedRoute === 'hero') {
            this.isMap = false;
            this.queryParams = queryString.parse(window.location.search.slice(1));
            this.loadedPageWithoutLocation = !this.hasInterestIdOrLocationId();

            if (this.loadedPageWithoutLocation) {
                setSearchInputPulsing(true);
            }

            this.resizeHandler();
            const throttledReziseHandler = _.throttle(this.resizeHandler, 100);
            window.addEventListener('resize', throttledReziseHandler);

            const throttledScrollHandler = _.throttle(this.handleScroll, 250);
            window.addEventListener('scroll', throttledScrollHandler);

            if (match.path === '/') {
                if (document.type === 'location') {
                    updateHistory('/map');
                    history.push('/map');
                    setMapCenter(new LatLng(document.lat, document.lng), document.place);
                    fetchVendorsWithoutService(
                        document.place,
                        geoUtils.getRadiusBoundingBox(
                            new LatLng({
                                lat: document.lat,
                                lng: document.lng,
                            }),
                            20,
                            20,
                        ),
                    );
                } else if (document.type === 'hero') {
                    updateHistory('/hero');
                    history.push('/hero');
                    this.fetchCanonicalLocationFromId(
                        this.queryParams['interestID'] || this.queryParams['locationID'],
                    );
                } else if (document.type === 'dki') {
                    updateHistory('/dki');
                    history.push('/dki');
                    this.fetchCanonicalLocationFromId(
                        this.queryParams['interestID'] || this.queryParams['locationID'],
                    );
                }
            }

            fetchKingdoms();
            if (document.type === 'location') {
                this.isMap = true;
            }
        }
    }

    componentDidUpdate(prevProps) {
        const { google, ready, showSlideOut, width, locCanonicalName, fetchLocationLatLng } = this.props;
        if (prevProps.ready !== ready && ready) {
            //ready, set a timeout for dispatching the side bar after the pins have dropped
            if (width < 750) {
                //show it immediately
                /*showSlideOut();*/
            } else {
                setTimeout(() => {
                    showSlideOut();
                }, 1000);
            }
        }
        // this.fetchCanonicalLocationFromId(this.queryParams["interestID"] || this.queryParams["locationID"]);

        if (google && locCanonicalName && (!prevProps.google || !prevProps.locCanonicalName)) {
            fetchLocationLatLng(locCanonicalName, google);
            this.doPlacesAutoComplete();
        }

        if (google && !prevProps.google) {
            const regionRestriction = {
                types: ['(regions)'],
                componentRestrictions: { country: ['us', 'pr'] },
            };
            const locationInput = ReactDOM.findDOMNode(this.locationInput);
            const searchBox = new google.maps.places.Autocomplete(locationInput, regionRestriction);
            const geocoder = new google.maps.Geocoder();

            searchBox.addListener('place_changed', () => {
                locationInput.blur();
                let place = searchBox.getPlace();
                if (place.geometry === undefined) {
                    // user pressed enter, didn't select a place. lets choose the first one
                    this.doPlacesAutoComplete(place['name']);
                } else {
                    geocoder.geocode({ placeId: place.place_id }, (results, status) => {
                        if (status === 'OK') {
                            this.startLocationSearch();
                        }
                    });
                }
            });
        }

        if (!this.loadedPageWithoutLocation && !!locCanonicalName && !prevProps.locCanonicalName) {
            this.startLocationSearch();
        }
    }

    doPlacesAutoComplete = (value) => {
        const { google, showLocErrorMsg, hideLocErrorMsg } = this.props;
        const locationInput = ReactDOM.findDOMNode(this.locationInput);

        hideLocErrorMsg();

        const currentInputText = this.locationInput.value;
        if (currentInputText.length === 0) {
            showLocErrorMsg('Please enter a location');
            return;
        }
        if (/^[a-zA-Z0-9\s,.'-]*$/.test(currentInputText) === false) {
            showLocErrorMsg("You've entered some invalid address characters");
            return;
        }

        if (!value) {
            value = currentInputText;
        }
        const regionRestriction = {
            types: ['(regions)'],
            componentRestrictions: { country: ['us', 'pr'] },
        };
        const service = new google.maps.places.AutocompleteService();
        service.getPlacePredictions({ input: value, ...regionRestriction }, (predictions, status) => {
            if (status !== google.maps.places.PlacesServiceStatus.OK) {
                showLocErrorMsg('Please provide a valid City, State, or ZIP code');
                return;
            }
            const geocoder = new google.maps.Geocoder();
            locationInput.value = predictions[0]['description'];
            let placeId = predictions[0]['place_id'];
            geocoder.geocode({ placeId: placeId }, (results, status) => {
                if (status === 'OK') {
                    this.startLocationSearch();
                }
            });
        });
    };

    hasInterestIdOrLocationId = () => {
        return !!this.queryParams['interestID'] || !!this.queryParams['locationID'];
    };

    resizeHandler = () => {
        const { resizeWindow } = this.props;
        resizeWindow(window.innerWidth, window.innerHeight);
    };

    handleScroll = () => {
        if (window.pageYOffset > 850 && !this.props.slideoutHasExtendedOnce) {
            this.props.slideOutExtend();
        }
    };

    handleInputFocus = () => {
        const { setSearchInputPulsing, setSearchButtonPulsing } = this.props;
        document.getElementById('location-search-input').value = '';

        setSearchInputPulsing(false);
        setSearchButtonPulsing(false);
    };

    handleInputBlur = () => {
        const { setSearchInputPulsing, setSearchButtonPulsing } = this.props;

        if (this.locationInput.value.trim() === '') {
            setSearchInputPulsing(true);
        } else {
            setSearchInputPulsing(false);
        }
    };

    handleKeyPress = (event) => {
        const { setSearchInputPulsing, setSearchButtonPulsing } = this.props;
        setSearchInputPulsing(false);

        if (this.locationInput.value.trim() !== '') {
            setSearchButtonPulsing(true);
        }

        if (event.key === 'Enter') {
            this.startLocationSearch();
        } else if (
            (event.key === 'Backspace' || event.key === 'Delete') &&
            this.locationInput.value.trim().length <= 1
        ) {
            setSearchButtonPulsing(false);
        }
    };

    startLocationSearch = () => {
        const {
            setSearchInputPulsing,
            setSearchButtonPulsing,
            history,
            updateHistory,
            google,
            locCanonicalName,
            doLocationSearch,
            unfocusVendor,
            setBlurOnly,
        } = this.props;
        setSearchInputPulsing(false);
        setSearchButtonPulsing(false);
        setBlurOnly();

        const currentInputText = this.locationInput.value;
        if (currentInputText !== locCanonicalName) {
            unfocusVendor();
            doLocationSearch(currentInputText, google);
        }

        //scroll up to the top
        const scrollToTop = () => {
            const c = document.documentElement.scrollTop || document.body.scrollTop;
            if (c > 0) {
                window.requestAnimationFrame(scrollToTop);
                window.scrollTo(0, c - c / 8);
            }
        };
        scrollToTop();

        // show the map
        updateHistory('/map');
        history.push('/map');
        this.isMap = true;
    };

    fetchCanonicalLocationFromId = (id) => {
        if (!id) {
            return;
        }

        // validate / sanitize id (should be a pure string of numbers)
        if (/^\d+$/.test(id) === false) {
            // TODO bubble this error up to the front end?
            return;
        }

        const { fetchLocationName } = this.props;
        fetchLocationName(id);
    };

    isOnServiceSelector = () => {
        const { location } = this.props;
        return location.pathname.indexOf('clinic') !== -1;
    };

    render() {
        const {
            google,
            interfaceState,
            panelExpanded,
            hideSlideOut,
            showSlideOut,
            location,
            order,
            slideOutRetract,
            slideoutExtended,
            locIsFetching,
            locCanonicalName,
            slideOutExtend,
            width,
            locErrorMessage,
            showErrorMessage,
            isSearchInputPulsing,
            isSearchButtonPulsing,
        } = this.props;
        const { aboutService } = interfaceState;

        const splitPath = location.pathname.split('/');
        let pathRoot = splitPath[1];
        return (
            <div className="location-selector">
                <div
                    className={`location-form-wrapper${this.isMap ? ' small' : ''}${
                        this.isOnServiceSelector() ? ' menus' : ''
                    }`}>
                    <div
                        className={`loc-input-form${this.isMap ? ' small' : ''}${
                            this.isOnServiceSelector() ? ' menus' : ''
                        }`}>
                        <img
                            id="location-loading-icon"
                            className={`location-loading-icon${this.isMap ? ' small' : ''}`}
                            src={loadingIcon}
                            style={{ display: locIsFetching ? 'inline' : 'none' }}
                            alt={'Loading your current location.'}
                        />
                        <div
                            className={`location-input-container${
                                isSearchInputPulsing && !this.isMap ? ' pulse' : ''
                            }`}>
                            <p>Find drug test clinics near</p>
                            <input
                                type="text"
                                id="location-search-input"
                                placeholder="Enter city, state, or zip"
                                defaultValue={locCanonicalName}
                                onClick={this.handleInputFocus}
                                onKeyDown={this.handleKeyPress}
                                onBlur={this.handleInputBlur}
                                ref={(locationInput) => {
                                    this.locationInput = locationInput;
                                }}
                            />
                        </div>
                        {width < 1051 ? (
                            <button
                                type="button"
                                className={`hs-button${isSearchButtonPulsing && !this.isMap ? ' pulse' : ''}`}
                                onClick={() => this.doPlacesAutoComplete()}>
                                <FontAwesomeIcon className="hs-button-search" icon="search" />
                            </button>
                        ) : (
                            <button
                                type="button"
                                className={`hs-button${isSearchButtonPulsing && !this.isMap ? ' pulse' : ''}`}
                                onClick={() => this.doPlacesAutoComplete()}>
                                <span className="text" />
                                <span className="icon">
                                    <FontAwesomeIcon className="icon-arrow-right" icon="angle-double-right" />
                                </span>
                            </button>
                        )}
                        <TransitionGroup component={null}>
                            <CSSTransition key={showErrorMessage} classNames="fade" timeout={300}>
                                {showErrorMessage ? (
                                    <div className={`location-input-error${showErrorMessage ? '' : ' hidden'}`}>
                                        <FontAwesomeIcon icon="exclamation-circle" />
                                        <p
                                            ref={(errorP) => {
                                                this.errorP = errorP;
                                            }}
                                            id="location-input-error-text"
                                            className="location-input-error-text">
                                            {locErrorMessage}
                                        </p>
                                    </div>
                                ) : (
                                    <div style={{ display: 'none' }} />
                                )}
                            </CSSTransition>
                        </TransitionGroup>
                    </div>
                </div>
                <TransitionGroup component={null}>
                    <CSSTransition
                        key={pathRoot}
                        classNames="modal"
                        timeout={{
                            enter: 1,
                            exit: 1,
                        }}>
                        <Switch location={location}>
                            <Route exact path="/hero">
                                <LocationHero google={google} width={width} />
                            </Route>
                        </Switch>
                    </CSSTransition>
                </TransitionGroup>
                <NotReadyOverlay>
                    <MapContainer height={555} google={google} />
                    {width > 750 ? (
                        <div
                            className={`show-list-button${panelExpanded ? ' show' : ' hide'}${
                                slideoutExtended ? ' extended' : ' retracted'
                            }`}
                            onClick={() => {
                                if (panelExpanded) {
                                    slideOutExtend();
                                    hideSlideOut();
                                } else {
                                    slideOutRetract();
                                    showSlideOut();
                                }
                            }}>
                            <FontAwesomeIcon className="list-button-content" icon="angle-left" />
                            <p className="show-list-button-text">Back</p>
                        </div>
                    ) : (
                        ''
                    )}
                    <Header
                        order={order}
                        width={width}
                        activeMode={panelExpanded ? 'list-mode' : 'map-mode'}
                        listClick={showSlideOut}
                        mapClick={hideSlideOut}
                    />
                    <SlideOutContainer showSlideOut={panelExpanded}>
                        <VendorList />
                    </SlideOutContainer>
                    <TransitionGroup component={null}>
                        <CSSTransition key={pathRoot} classNames="fade" timeout={200}>
                            <Switch location={location}>
                                <Route path="/clinic/:vendorId" component={ServiceSelector} />
                            </Switch>
                        </CSSTransition>
                    </TransitionGroup>
                </NotReadyOverlay>
                <TransitionGroup component={null} appear={true}>
                    <CSSTransition
                        key={location.pathname}
                        classNames="modal"
                        timeout={{
                            enter: 400,
                            exit: 700,
                        }}>
                        <Switch location={location}>
                            <Route
                                exact
                                path="/clinic/:vendorId/:kingdomId/:phylumId/modal"
                                render={(props) => <ModalDialog {...props} aboutService={aboutService} />}
                            />
                            <Route
                                exact
                                path="/clinic/:vendorId/:kingdomId/:phylumId/options"
                                render={(props) => <ModifierDrawer {...props} />}
                            />
                            <Route
                                exact
                                path="/map/warning"
                                render={(props) => <ServiceSpecificSearchDialog {...props} />}
                            />
                        </Switch>
                    </CSSTransition>
                </TransitionGroup>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        ready: state.interface.ready,
        width: state.interface.width,
        interfaceState: state.interface,
        center: state.map.center,
        panelExpanded: state.interface.showSlideOut,
        aboutService: state.interface.aboutService,
        order: state.order,
        slideoutExtended: state.interface.showExtendedSlideOut,
        slideoutHasExtendedOnce: state.interface.slideoutHasExtendedOnce,
        locIsFetching: state.locationLookup.isFetching,
        locCanonicalName: state.locationLookup.canonicalName,
        isFetchingLatLng: state.locationLookup.isFetchingLatLng,
        locErrorMessage: state.locationLookup.error,
        showErrorMessage: state.locationLookup.showError,
        isSearchInputPulsing: state.interface.isSearchInputPulsing,
        isSearchButtonPulsing: state.interface.isSearchButtonPulsing,
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            setMapCenter: setMapCenter,
            setBlurOnly: setBlurOnly,
            showSlideOut: showSlideOut,
            hideSlideOut: hideSlideOut,
            resizeWindow: resizeWindow,
            fetchKingdoms: fetchKingdoms,
            fetchVendorsWithoutService: fetchVendorsWithoutService,
            slideOutExtend: slideOutExtend,
            slideOutRetract: slideOutRetract,
            fetchLocationName: fetchLocationCanonicalNameFromId,
            fetchLocationLatLng: fetchLocationLatLngFromCanonicalName,
            doLocationSearch: doLocationSearch,
            showLocErrorMsg: showLocErrorMsg,
            hideLocErrorMsg: hideLocErrorMsg,
            unfocusVendor: unfocusVendor,
            updateHistory: updateHistory,
            setSearchInputPulsing: setSearchInputPulsing,
            setSearchButtonPulsing: setSearchButtonPulsing,
        },
        dispatch,
    );
};
export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )(
        GoogleApiComponent({
            apiKey: 'AIzaSyC-GUiR2VjAQj5qZplTQ-u7sGEuGy-Y9TA',
        })(LocationSelector),
    ),
);
