import * as actionTypes from '../actionTypes';

const initialState = {
    placeId: null,
    zip: null,
    selectedVendor: null,
    vendorConfirmed: false,
    selectedKingdom: null,
    kingdomSelectedFromDocument: false,
    useDocumentKingdom: 0,
    selectedPhylum: null,
    selectedService: null,
    features: [],
    selectedFeatures: {},
    vendorInsufficient: false,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case actionTypes.RECEIVE_VENDORS:
            return {
                ...state,
                placeId: action.placeId,
            };
        case actionTypes.SET_ZIP:
            return {
                ...state,
                zip: action.zip,
            };
        case actionTypes.SELECT_VENDOR:
            return {
                ...state,
                selectedVendor: action.vendor,
                vendorConfirmed: false,
            };
        case actionTypes.CONFIRM_VENDOR:
            return {
                ...state,
                vendorConfirmed: true,
            };
        case actionTypes.CLEAR_SELECTED_VENDOR:
            return {
                ...state,
                selectedVendor: null,
                vendorConfirmed: false,
            };
        case actionTypes.SELECT_KINGDOM:
            return {
                ...state,
                selectedKingdom: action.id,
                kingdomSelectedFromDocument: action.kingdomSelectedFromDocument,
                selectedPhylum: null,
            };
        case actionTypes.CLEAR_SELECTED_KINGDOM:
            if (state.kingdomSelectedFromDocument) {
                return {
                    ...state,
                    selectedPhylum: null,
                };
            } else {
                return {
                    ...state,
                    selectedKingdom: null,
                    selectedPhylum: null,
                };
            }
        case actionTypes.USE_DOCUMENT_KINGDOM:
            return {
                ...state,
                useDocumentKingdom: state.useDocumentKingdom + 1,
            };
        case actionTypes.SELECT_PHYLUM:
            return {
                ...state,
                selectedPhylum: action.id,
            };
        case actionTypes.CLEAR_SELECTED_PHYLUM:
            return {
                ...state,
                selectedPhylum: null,
            };
        case actionTypes.SELECT_SERVICE:
            return {
                ...state,
                selectedService: action.service,
            };
        case actionTypes.CLEAR_SELECTED_SERVICE:
            return {
                ...state,
                selectedService: null,
                features: [],
            };
        case actionTypes.SHOW_MODIFIER_DRAWER:
            return {
                ...state,
                features: action.features,
            };
        case actionTypes.HIDE_MODIFIER_DRAWER:
            return {
                ...state,
                features: [],
            };
        case actionTypes.INIT_FEATURE:
            return {
                ...state,
                selectedFeatures: {
                    ...state.selectedFeatures,
                    [state.selectedService.id]: {
                        ...state.selectedFeatures[state.selectedService.id],
                        [action.feature]: [],
                    },
                },
            };
        case actionTypes.UPDATE_FEATURE_OPTION:
            return {
                ...state,
                selectedFeatures: {
                    ...state.selectedFeatures,
                    [state.selectedService.id]: {
                        ...state.selectedFeatures[state.selectedService.id],
                        [action.feature]: action.option,
                    },
                },
            };
        case actionTypes.ADD_FEATURE_OPTION:
            return {
                ...state,
                selectedFeatures: {
                    ...state.selectedFeatures,
                    [state.selectedService.id]: {
                        ...state.selectedFeatures[state.selectedService.id],
                        [action.feature]:
                            state.selectedFeatures[state.selectedService.id] &&
                            state.selectedFeatures[state.selectedService.id][action.feature]
                                ? [
                                      ...state.selectedFeatures[state.selectedService.id][action.feature],
                                      action.option,
                                  ]
                                : [action.option],
                    },
                },
            };
        case actionTypes.REMOVE_FEATURE_OPTION:
            return {
                ...state,
                selectedFeatures: {
                    ...state.selectedFeatures,
                    [state.selectedService.id]: {
                        ...state.selectedFeatures[state.selectedService.id],
                        [action.feature]: [
                            ...state.selectedFeatures[state.selectedService.id][action.feature].filter(
                                (option) => option !== action.option,
                            ),
                        ],
                    },
                },
            };
        case actionTypes.SET_VENDOR_INSUFFICIENT:
            return {
                ...state,
                vendorInsufficient: true,
            };
        default:
            return state;
    }
}
