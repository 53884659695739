import React, { Component } from 'react';
import '../../style/views/parts/SlidingPhylumPanel.css';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { fetchPhyla, selectPhylum } from '../../store/actions';
import * as constants from '../../misc/Constants';
import PhylumListItem from './listitems/PhylumListItem';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { updateHistory } from 'store/actions';

class SlidingPhylumPanel extends Component {
    componentDidMount() {
        const { fetchPhyla, order } = this.props;
        fetchPhyla(order.selectedKingdom);
    }

    render() {
        const { order, phyla, selectPhylum, history, updateHistory, kingdom, kingdomsById, returnToMap } =
            this.props;

        const localPhyla = phyla[kingdom];
        const unloaded = !localPhyla;
        const loading = localPhyla && localPhyla.isFetching;
        const loaded = localPhyla && !localPhyla.isFetching && !localPhyla.didInvalidate;
        if (loading || unloaded) {
            return (
                <div className="phylum-panel-container">
                    <div className="phylum-panel">{constants.DEFAULT_PHYLA}</div>
                </div>
            );
        } else if (loaded) {
            let loadedTypes = []
                .concat(Object.keys(localPhyla.items))
                .sort((a, b) => {
                    let aSort = localPhyla.items[a].sortOrder != null ? localPhyla.items[a].sortOrder : 99;
                    let bSort = localPhyla.items[b].sortOrder != null ? localPhyla.items[b].sortOrder : 99;
                    return aSort - bSort;
                })
                .map((phylum) => {
                    return (
                        <PhylumListItem
                            key={localPhyla.items[phylum].id}
                            phylumId={localPhyla.items[phylum].id}
                            name={localPhyla.items[phylum].name}
                            customClass={'sub-item'}
                            onClick={() => {
                                selectPhylum(localPhyla.items[phylum].id);
                                const hist =
                                    '/clinic/' +
                                    order.selectedVendor.vendorId +
                                    '/' +
                                    kingdom +
                                    '/' +
                                    localPhyla.items[phylum].id;
                                updateHistory(hist);
                                history.push(hist);
                            }}
                        />
                    );
                });
            return (
                <div className="phylum-panel-container">
                    <div
                        className="phylum-panel-title"
                        style={order.kingdomSelectedFromDocument ? { top: '28px' } : {}}
                    >
                        {kingdomsById[kingdom].wKingdomHeader}
                    </div>
                    <div
                        className="phylum-panel-back-button"
                        style={order.kingdomSelectedFromDocument ? { top: '18px' } : {}}
                        onClick={() => {
                            returnToMap();
                        }}
                    >
                        <FontAwesomeIcon className="back-button-left-arrow" icon="angle-left" />
                        Clinics
                    </div>
                    <div
                        className="phylum-panel"
                        style={order.kingdomSelectedFromDocument ? { top: '60px' } : {}}
                        onClick={(e) => {
                            e.stopPropagation();
                        }}
                    >
                        {loadedTypes}
                    </div>
                </div>
            );
        }
    }
}

const mapStateToProps = (state) => {
    return {
        order: state.order,
        phyla: state.phyla,
        kingdomsById: state.kingdomsById,
    };
};
const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            fetchPhyla: fetchPhyla,
            selectPhylum: selectPhylum,
            updateHistory: updateHistory,
        },
        dispatch,
    );
};
export default connect(mapStateToProps, mapDispatchToProps)(SlidingPhylumPanel);
