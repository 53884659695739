import React from 'react';
import PropTypes from 'prop-types';
import '../../style/views/parts/SlideOutContainer.css';

const SlideOutContainer = (props) => {
    const { children, showSlideOut } = props;

    // START HACK
    // we need to move the google zoom buttons so the user can see them
    const googleZoomButtons = document.getElementsByClassName('gm-bundled-control-on-bottom')[0];
    if (showSlideOut) {
        if (!!googleZoomButtons && !googleZoomButtons.classList.contains('inset')) {
            googleZoomButtons.classList.add('inset');
        }
    } else {
        if (!!googleZoomButtons && googleZoomButtons.classList.contains('inset')) {
            googleZoomButtons.classList.remove('inset');
        }
    }
    //END HACK
    return (
        <div id="slide-out" className={`slide-out${showSlideOut ? ' show' : ' hide'}`}>
            {children}
        </div>
    );
};
SlideOutContainer.propTypes = {
    showSlideOut: PropTypes.bool,
    children: PropTypes.object,
};

export default SlideOutContainer;
