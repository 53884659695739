import * as actionTypes from '../actionTypes';

export default function (
    state = {
        isFetching: false,
        isFetchingLatLng: false,
        id: null,
        canonicalName: null,
        lat: null,
        lng: null,
        isMap: false,
        showError: false,
        error: null,
    },
    action,
) {
    switch (action.type) {
        case actionTypes.FETCH_LOC_NAME:
            return Object.assign({}, state, {
                isFetching: true,
                id: action.id,
            });
        case actionTypes.RECEIVE_LOC_NAME:
            return Object.assign({}, state, {
                isFetching: false,
                canonicalName: action.canonicalName,
            });
        case actionTypes.ERROR_LOC_NAME:
            return Object.assign({}, state, {
                isFetching: false,
            });
        case actionTypes.FETCH_LOC_LAT_LNG:
            return Object.assign({}, state, {
                isFetchingLatLng: true,
            });
        case actionTypes.RECEIVE_LOC_LAT_LNG:
            return Object.assign({}, state, {
                isFetchingLatLng: false,
                lat: action.lat,
                lng: action.lng,
            });
        case actionTypes.SHOW_LOCATION_ERROR_MESSAGE:
            return Object.assign({}, state, {
                error: action.msg,
                showError: true,
            });
        case actionTypes.HIDE_LOCATION_ERROR_MESSAGE:
            return Object.assign({}, state, {
                showError: false,
            });
        default:
            return state;
    }
}
