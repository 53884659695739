import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as handoffHelper from '../../util/HandoffHelper';
import { selectVendor, unfocusVendor } from '../../store/actions';
import { updateHistory } from 'store/actions';

class VendorDetailsPopup extends Component {
    registerNowClick = () => {
        const { vendor, selectVendor, history, order, unfocusVendor, updateHistory } = this.props;

        window.location.href = 'https://app.health-street.net/order?vendorId=' + vendor.vendorId;
        return;
        if (order.selectedService && order.selectedVendor && order.vendorInsufficient) {
            //we're selecting a new vendor after the one we picked didn't offer the service we wanted.
            order.selectedVendor = vendor;
            handoffHelper.handoffToFunnel(order);
        } else {
            selectVendor(vendor);
            const hist = '/clinic/' + vendor.vendorId;
            updateHistory(hist);
            history.push(hist);
            unfocusVendor();
        }
    };

    render() {
        const { vendor } = this.props;
        return (
            <div className="vendor-popup">
                <hr />
                <div className="address-section">
                    <div className="address-lines">
                        <span className="vendor-address-1">{vendor.address}</span>
                        <span className="vendor-address-2">{vendor.address2 ? vendor.address2 : ''}</span>
                        <span className="vendor-city-state-zip">
                            {vendor.city + ', ' + vendor.state + ' ' + vendor.zip}
                        </span>
                    </div>
                </div>
                <hr />
                <div className="vendor-detail-cta">
                    <div
                        className="register-button"
                        onClick={(e) => {
                            const scrollToTop = () => {
                                const c = document.documentElement.scrollTop || document.body.scrollTop;
                                if (c > 0) {
                                    window.requestAnimationFrame(scrollToTop);
                                    window.scrollTo(0, c - c / 8);
                                }
                            };
                            scrollToTop();
                            e.stopPropagation();
                            e.nativeEvent.stopPropagation();
                            this.registerNowClick();
                        }}>
                        Get Tested Here
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        order: state.order,
        focusedVendor: state.interface.focusedVendor,
    };
};
const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            selectVendor: selectVendor,
            unfocusVendor: unfocusVendor,
            updateHistory: updateHistory,
        },
        dispatch,
    );
};
export default connect(mapStateToProps, mapDispatchToProps)(VendorDetailsPopup);
