import React from 'react';
import '../../../style/views/parts/loaders/SubTypeLoader.css';

const SubTypeLoader = () => {
    return (
        <div className="subtype-loading-masker">
            <div className="white-widget grey-bg author-area">
                <div className="auth-info row">
                    <div className="subtype-timeline-wrapper">
                        <div className="timeline-item">
                            <div className="subtype-animated-background">
                                <div className="subtype-background-masker header-left"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default SubTypeLoader;
