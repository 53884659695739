import React from 'react';
import '../../../style/views/parts/loaders/IconLoader.css';

const IconLoader = (props) => (
    <div className="loader-6">
        <span />
        <span />
        <span />
        <span />
    </div>
);
export default IconLoader;
