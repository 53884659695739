import * as actionTypes from '../actionTypes';

export default function (state = {}, action) {
    switch (action.type) {
        case actionTypes.RECEIVE_FAMILIES:
            let newFamilies = action.families.reduce((acc, cur, i) => {
                acc[cur.id] = cur;
                return acc;
            }, {});
            return Object.assign({}, state, newFamilies);
        default:
            return state;
    }
}
