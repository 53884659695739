import React from 'react';
import PropTypes from 'prop-types';
import '../../../style/views/parts/listitems/ListItem.css';
import ServiceDetailContentItem from './itemparts/ServiceDetailContentItem';
import PriceAddTestContentItem from './itemparts/PriceAddTestContentItem';
/*
import * as handoffHelper from '../../../../util/HandoffHelper';
*/

const ServiceListItem = (props) => {
    const { service, detailClick } = props;

    return (
        <div className={`list-item`}>
            <div className="item-left-justified">
                <ServiceDetailContentItem
                    id={service.id}
                    name={service.portalServiceName}
                    detailClick={detailClick}
                    details={service.serviceDetails ? service.serviceDetails : 'No Additional Details'}
                />
            </div>
            <div className={'item-right-justified'}>
                <PriceAddTestContentItem {...props} />
            </div>
        </div>
    );
};
ServiceListItem.propTypes = {
    service: PropTypes.object.isRequired,
    detailClick: PropTypes.func.isRequired,
    onClick: PropTypes.func.isRequired,
};
export default ServiceListItem;
