import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Map from './Map';
import Marker from './Marker';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
    fetchVendorsWithoutService,
    focusTargetVendor,
    setMapCenter,
    setTargetVendor,
    unfocusVendor,
} from '../store/actions';

class MapContainer extends Component {
    render() {
        const { markers, google, selectPin, center } = this.props;
        let markerArray = [];
        let mapBounds = {};
        if (google) {
            mapBounds = new google.maps.LatLngBounds();
            for (let key in markers) {
                if (markers.hasOwnProperty(key)) {
                    let latLng = new google.maps.LatLng(markers[key].lat, markers[key].lng);
                    mapBounds.extend(latLng);
                    markerArray.push(
                        <Marker
                            key={key}
                            position={markers[key]}
                            vendorId={parseInt(key)}
                            selectPin={selectPin}
                        />,
                    );
                }
            }
            return (
                <Map {...this.props} bounds={markerArray.length > 0 ? mapBounds : null} center={center}>
                    {markerArray}
                </Map>
            );
        }
        return <div />;
    }
}

MapContainer.propTypes = {
    height: PropTypes.number.isRequired,
    markers: PropTypes.object,
    regionSelected: PropTypes.string,
    selectPin: PropTypes.func,
};
const mapStateToProps = (state) => {
    return {
        center: state.map.center,
        markers: state.map.markers,
        placeId: state.map.placeId,
        showSlideOut: state.interface.showSlideOut,
        width: state.interface.width,
        order: state.order,
        vendors: state.vendors,
        focusedVendor: state.interface.focusedVendor,
        hoverVendorTile: state.interface.hoverVendorTile,
    };
};
const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            setMapCenter: setMapCenter,
            fetchVendorsWithoutService: fetchVendorsWithoutService,
            setTargetVendor: setTargetVendor,
            focusTargetVendor: focusTargetVendor,
            unfocusVendor: unfocusVendor,
        },
        dispatch,
    );
};
export default connect(mapStateToProps, mapDispatchToProps)(MapContainer);
