import React, { Component } from 'react';
import '../../style/views/parts/Header.css';

class Header extends Component {
    render() {
        const { order, width, activeMode, listClick, mapClick } = this.props;
        const showMobile = width < 750;

        if (!showMobile) {
            return (
                <div className="slide-out-header">
                    {order.selectedService && order.vendorInsufficient ? (
                        <div className="split-header">
                            <span>Health Street Clinics</span>
                            <span className="subtext"> offering {order.selectedService.portalServiceName}</span>
                        </div>
                    ) : (
                        'Choose your clinic'
                    )}
                </div>
            );
        }
        if (document.will) {
            return (
                <div className="slide-out-header will">
                    <div className=""></div>
                </div>
            );
        } else {
            return (
                <div className="slide-out-header jared">
                    <div className="slide-out-greeting">
                        {order.selectedService && order.vendorInsufficient ? (
                            <div className="split-header">
                                <span>Health Street Clinics</span>
                                <span className="subtext">
                                    {' '}
                                    offering {order.selectedService.portalServiceName}
                                </span>
                            </div>
                        ) : (
                            'Choose your clinic'
                        )}
                    </div>
                    <div className="tab-container">
                        <div
                            className={`map-view ${activeMode}`}
                            onClick={() => {
                                mapClick();
                            }}>
                            Map View
                        </div>
                        <div
                            className={`list-view ${activeMode}`}
                            onClick={() => {
                                listClick();
                            }}>
                            List View
                        </div>
                    </div>
                </div>
            );
        }
    }
}

export default Header;
