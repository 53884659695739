import * as constants from '../misc/Constants';
import queryString from 'querystring';

export const handoffToFunnel = (order) => {
    let kingdom, service, vendor, options, place, zip;
    kingdom = order.selectedKingdom;

    if (order.selectedService) {
        service = order.selectedService.id;
    }
    vendor = order.selectedVendor;
    if (order.selectedFeatures) {
        options = order.selectedFeatures;
    }
    place = order.placeId;
    zip = order.zip;

    const origin = constants.appLocation;
    const basePath = '/order';

    let finalQuery = {
        ftrc: 1,
    };

    if (service) {
        finalQuery['service'] = service.toString();
    }
    if (vendor) {
        finalQuery['vendor'] = vendor.vendorId.toString();
    }
    if (options) {
        finalQuery['options'] = JSON.stringify(options);
    }
    if (place) {
        finalQuery['place'] = place.toString();
    }
    if (zip) {
        finalQuery['zip'] = zip.toString();
    }
    if (kingdom) {
        finalQuery['kingdom'] = kingdom.toString();
    }
    finalQuery['origin'] = window.location.href;
    //now leaving FTRC. See ya later suckerzzzz
    const cur = new Date();
    const expires = new Date(
        cur.getFullYear(),
        cur.getMonth(),
        cur.getDate(),
        cur.getHours(),
        cur.getMinutes() + 10,
    );
    document.cookie = 'visited-hs-funnel=true; expires=' + expires.toUTCString();
    window.location.href = encodeURI(origin + basePath) + `?${queryString.stringify(finalQuery)}`;
};
