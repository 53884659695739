import LatLng from './LatLng';
import LatLngBounds from './LatLngBounds';

/**
 * Gets a latLng object of the bounding box represented by the center latLng and the radius
 * @param latLng -- the center of the bounding box
 * @param height -- the radius of the bounding box
 * @param width -- (optional) the width of the bounding box to return. If omitted, height is used.
 */
export const getRadiusBoundingBox = (latLng, height, width) => {
    const latRad = toRads(latLng.lat());
    const lngRad = toRads(latLng.lng());
    const latRadModifier = height / 3959;
    const lngRadModifier = width ? width / 3959 : latRadModifier;
    const sw = new LatLng(toDeg(latRad - latRadModifier), toDeg(lngRad - lngRadModifier));
    const ne = new LatLng(toDeg(latRad + latRadModifier), toDeg(lngRad + lngRadModifier));
    return new LatLngBounds(sw, ne);
};

export const toRads = (deg) => {
    return deg * (Math.PI / 180);
};
export const toDeg = (rad) => {
    return rad * (180 / Math.PI);
};
export const getDistance = (lat1, lng1, lat2, lng2) => {
    const term1 = lat2 - lat1;
    const term2 = lng2 - lng1;
    return Math.sqrt(Math.pow(term1, 2) + Math.pow(term2, 2));
};
