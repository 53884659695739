export const RESIZE_WINDOW = 'RESIZE_WINDOW';

export const SET_READY = 'SET_READY';
export const SET_NOT_READY = 'SET_NOT_READY';
export const SET_DEPTH = 'SET_DEPTH';
export const SET_BLUR_ONLY = 'SET_BLUR_ONLY';

export const CLEAR_MAP = 'CLEAR_MAP';
export const SET_ZIP = 'SET_ZIP';
export const REQUEST_VENDORS = 'REQUEST_VENDORS';
export const RECEIVE_VENDORS = 'RECEIVE_VENDORS';
export const REQUEST_KINGDOMS = 'REQUEST_KINGDOMS';
export const RECEIVE_KINGDOMS = 'RECEIVE_KINGDOMS';
export const REQUEST_PHYLA = 'REQUEST_PHYLA';
export const RECEIVE_PHYLA = 'RECEIVE_PHYLA';
export const REQUEST_FAMILIES = 'REQUEST_FAMILIES';
export const RECEIVE_FAMILIES = 'RECEIVE_FAMILIES';

export const ABOUT_CLICK = 'ABOUT_CLICK';
export const ABOUT_CLEAR = 'ABOUT_CLEAR';

export const SET_MAP_CENTER = 'SET_MAP_CENTER';
export const SET_MARKERS = 'SET_MARKERS';
export const REMOVE_MARKERS = 'REMOVE_MARKERS';

export const SHOW_SLIDE_OUT = 'SHOW_SLIDE_OUT';
export const HIDE_SLIDE_OUT = 'HIDE_SLIDE_OUT';

export const SET_TARGET_VENDOR = 'SET_TARGET_VENDOR';
export const FOCUS_TARGET_VENDOR = 'FOCUS_TARGET_VENDOR';
export const UNFOCUS_VENDOR = 'UNFOCUS_VENDOR';
export const HOVER_VENDOR_TILE = 'HOVER_VENDOR_TILE';
export const CLEAR_VENDOR_TILE = 'CLEAR_VENDOR_TILE';
export const SELECT_VENDOR = 'SELECT_VENDOR';
export const SET_VENDOR_INSUFFICIENT = 'SET_VENDOR_INSUFFICIENT';
export const CONFIRM_VENDOR = 'CONFIRM_VENDOR';
export const CLEAR_SELECTED_VENDOR = 'CLEAR_SELECTED_VENDOR';
export const SHOW_ALL_VENDORS = 'SHOW_ALL_VENDORS';
export const SHOW_PREFERRED_VENDORS = 'SHOW_PREFERRED_VENDORS';

export const SELECT_KINGDOM = 'SELECT_KINGDOM';
export const CLEAR_SELECTED_KINGDOM = 'CLEAR_SELECTED_KINGDOM';
export const USE_DOCUMENT_KINGDOM = 'USE_DOCUMENT_KINGDOM';

export const SELECT_PHYLUM = 'SELECT_PHYLUM';
export const CLEAR_SELECTED_PHYLUM = 'CLEAR_SELECTED_PHYLUM';

export const SELECT_SERVICE = 'SELECT_SERVICE';
export const CLEAR_SELECTED_SERVICE = 'CLEAR_SELECTED_SERVICE';

export const SHOW_MODIFIER_DRAWER = 'SHOW_MODIFIER_DRAWER';
export const HIDE_MODIFIER_DRAWER = 'HIDE_MODIFIER_DRAWER';
export const INIT_FEATURE = 'INIT_FEATURE';
export const ADD_FEATURE_OPTION = 'ADD_FEATURE_OPTION'; //for CheckGroup
export const REMOVE_FEATURE_OPTION = 'REMOVE_FEATURE_OPTION'; //for CheckGroup
export const UPDATE_FEATURE_OPTION = 'UPDATE_FEATURE_OPTION'; //for RadioGroup

export const THROW_ERROR = 'THROW_ERROR';
export const CLEAR_ERROR = 'CLEAR_ERROR';

export const SLIDE_OUT_EXTEND = 'SLIDE_OUT_EXTEND';
export const SLIDE_OUT_RETRACT = 'SLIDE_OUT_RETRACT';

export const START_ALT_SERVICE_FETCH = 'START_ALT_SERVICE_FETCH';
export const STOP_ALT_SERVICE_FETCH = 'STOP_ALT_SERVICE_FETCH';

export const FETCH_LOC_NAME = 'START_FETCH_LOC_NAME';
export const RECEIVE_LOC_NAME = 'RECEIVE_LOC_NAME';
export const ERROR_LOC_NAME = 'ERROR_LOC_NAME';
export const FETCH_LOC_LAT_LNG = 'FETCH_LOC_LAT_LNG';
export const RECEIVE_LOC_LAT_LNG = 'RECEIVE_LOC_LAT_LNG';
export const SHOW_LOCATION_ERROR_MESSAGE = 'SHOW_LOCATION_ERROR_MESSAGE';
export const HIDE_LOCATION_ERROR_MESSAGE = 'HIDE_LOCATION_ERROR_MESSAGE';

export const UPDATE_HISTORY = 'UPDATE_HISTORY';

export const SET_SEARCH_INPUT_PULSE = 'SET_SEARCH_INPUT_PULSE';
export const SET_SEARCH_BUTTON_PULSE = 'SET_SEARCH_BUTTON_PULSE';
