import React from 'react';
import PropTypes from 'prop-types';

class RadioGroup extends React.Component {
    componentDidMount() {
        const { selectedValue, onChange, feature } = this.props;
        onChange(feature.id, [selectedValue]);
    }
    getChildContext() {
        const { name, selectedValue, onChange } = this.props;
        return {
            radioGroup: {
                name,
                selectedValue,
                onChange,
            },
        };
    }

    render() {
        const { Component, children } = this.props;
        return <Component>{children}</Component>;
    }
}

RadioGroup.defaultProps = {
    Component: 'div',
};

RadioGroup.propTypes = {
    name: PropTypes.string,
    selectedValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
    onChange: PropTypes.func,
    children: PropTypes.node.isRequired,
    Component: PropTypes.oneOfType([PropTypes.string, PropTypes.func, PropTypes.object]),
};

RadioGroup.childContextTypes = {
    radioGroup: PropTypes.object,
};
export default RadioGroup;
