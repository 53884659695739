import * as actionTypes from '../actionTypes';

export default function (state = {}, action) {
    switch (action.type) {
        case actionTypes.SHOW_MODIFIER_DRAWER:
            let newFeatures = {};
            action.features.forEach((feature) => {
                newFeatures[feature.id] = feature;
            });
            return Object.assign({}, state, newFeatures);
        case actionTypes.CLEAR_SELECTED_SERVICE:
            return Object.assign({}, state);
        default:
            return state;
    }
}
