import React, { Component } from 'react';
import '../style/views/ModifierDrawer.css';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
    clearSelectedService,
    confirmFeatures,
    fetchVendorsWithService,
    hideModifierDrawer,
} from '../store/actions';
import '../style/loading/Loading.css';
import { updateHistory } from 'store/actions';

class ServiceSpecificSearchDialog extends Component {
    render() {
        const { ready, history, updateHistory } = this.props;

        const dialogFooterContent = ready ? (
            <div
                className="modifier-confirm"
                onClick={() => {
                    updateHistory('/map');
                    history.push('/map');
                }}
            >
                Let me see!
            </div>
        ) : (
            <div className="lds-css ng-scope">
                <div className="lds-double-ring-mini">
                    <div></div>
                    <div></div>
                </div>
            </div>
        );

        return (
            <div className="modifier-drawer-container">
                <div className="modifier-drawer" style={{ maxWidth: '400px' }}>
                    <div className="modifier-header centered">
                        <div className="modifier-title">Updating locations...</div>
                    </div>
                    <div className="modifier-body">
                        <div className="modifier-feature">
                            <div className="feature-name" style={{ marginTop: '10px', marginBottom: '10px' }}>
                                It looks like this service is not offered at the clinic you selected..
                            </div>
                            <div className="feature-description centered">
                                We're refreshing the map to show only the locations that offer the service you are
                                looking for.
                            </div>
                        </div>
                    </div>
                    <div className="modifier-footer" style={{ marginTop: '30px', height: '42px' }}>
                        {dialogFooterContent}
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        ready: state.interface.ready,
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            hideModifierDrawer: hideModifierDrawer,
            clearSelectedService: clearSelectedService,
            confirmFeatures: confirmFeatures,
            fetchVendorsWithService: fetchVendorsWithService,
            updateHistory: updateHistory,
        },
        dispatch,
    );
};
export default connect(mapStateToProps, mapDispatchToProps)(ServiceSpecificSearchDialog);
