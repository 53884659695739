import * as actionTypes from '../actionTypes';

export default function (state = {}, action) {
    switch (action.type) {
        case actionTypes.RECEIVE_KINGDOMS:
            let newKingdoms = action.kingdoms.reduce((acc, cur) => {
                acc[cur.id] = cur;
                return acc;
            }, {});
            return Object.assign({}, state, newKingdoms);

        default:
            return state;
    }
}
