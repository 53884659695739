import React from 'react';
import PropTypes from 'prop-types';
import '../../../style/views/parts/listitems/FamilyGroupListItem.css';

const FamilyGroupListItem = (props) => {
    const { name, services } = props;
    return (
        <div className={'web-cat-group'}>
            <div className={'web-cat-header'}>{name}</div>
            {services}
        </div>
    );
};
FamilyGroupListItem.propTypes = {
    name: PropTypes.string.isRequired,
    services: PropTypes.array,
};
export default FamilyGroupListItem;
