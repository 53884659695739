import * as actionTypes from '../actionTypes';

const families = (
    state = {
        isFetching: false,
        didInvalidate: true,
        items: [],
    },
    action,
) => {
    switch (action.type) {
        case actionTypes.REQUEST_FAMILIES:
            return Object.assign({}, state, {
                isFetching: true,
                didInvalidate: false,
            });
        case actionTypes.RECEIVE_FAMILIES:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: false,
                items: action.families,
                lastUpdated: action.receivedAt,
            });
        default:
            return state;
    }
};

export default function (state = {}, action) {
    switch (action.type) {
        case actionTypes.RECEIVE_FAMILIES:
        case actionTypes.REQUEST_FAMILIES:
            return Object.assign({}, state, {
                [action.phylumId]: families(state[action.phylumId], action),
            });
        default:
            return state;
    }
}
