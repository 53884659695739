import React from 'react';
import PropTypes from 'prop-types';
import '../../style/views/parts/KingdomButton.css';
import IconLoader from './loaders/IconLoader';

const KingdomButton = (props) => {
    const { active, kingdom } = props;
    const dangerousInnerHtml = {
        __html: kingdom.svg,
    };

    const icon = kingdom.svg ? (
        <div className={'kingdom-icon'} dangerouslySetInnerHTML={dangerousInnerHtml} />
    ) : (
        <div className={'kingdom-icon'}>
            <IconLoader />
        </div>
    );
    return (
        <div
            className={`kingdom-button${active ? ' active' : ''}`}
            onClick={(e) => {
                e.stopPropagation();
                props.onClick(kingdom.id);
            }}
        >
            {icon}
            <div className="kingdom-label">{kingdom.name}</div>
            <div className="kingdom-label-mobile">{kingdom.shortName}</div>
        </div>
    );
};

KingdomButton.propTypes = {
    kingdom: PropTypes.object.isRequired,
    active: PropTypes.bool.isRequired,
    onClick: PropTypes.func.isRequired,
};
export default KingdomButton;
