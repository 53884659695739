import React, { Component } from 'react';
import '../../style/views/parts/ServiceSelector.css';
import { withRouter } from 'react-router';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { Switch, Route } from 'react-router-dom';
import KingdomList from './panels/KingdomList';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import SlidingPhylumPanel from './SlidingPhylumPanel';
import {
    clearOrder,
    useDocumentKingdom,
    focusTargetVendor,
    hideSlideOut,
    setTargetVendor,
} from '../../store/actions';
import SlidingFamilyPanel from './SlidingFamilyPanel';
import { updateHistory } from 'store/actions';

/**
 * ServiceSelector is the portion of ftrc_local that is displayed once a clinic is selected.
 * It overlays the LocationSelector, and contains the panels needed to select a service.
 */
class ServiceSelector extends Component {
    componentDidMount() {
        const { order, history, updateHistory, useDocumentKingdom } = this.props;
        //hacky as shit for back button functionality
        const haveCookie = document.cookie.indexOf('visited-hs-funnel') > -1;
        if (order.selectedKingdom && order.kingdomSelectedFromDocument && !haveCookie) {
            const hist = '/clinic/' + order.selectedVendor.vendorId + '/' + order.selectedKingdom;
            updateHistory(hist);
            history.push(hist);
            useDocumentKingdom();
        }
        document.cookie = 'visited-hs-funnel= ; expires = Thu, 01 Jan 1970 00:00:00 GMT';
    }

    returnToMap = () => {
        const { history, updateHistory, clearOrder } = this.props;
        //give time for leaving animations to run
        setTimeout(() => {
            clearOrder();
        }, 400);
        updateHistory('/map');
        history.push('/map');
    };

    render() {
        const { location, order, focusTargetVendor, hideSlideOut, width, lastFocusedVendor, setTargetVendor } =
            this.props;
        return (
            <div className="service-selector" onClick={() => this.returnToMap()}>
                <div
                    className="address-container"
                    onClick={() => {
                        setTargetVendor(lastFocusedVendor);
                        focusTargetVendor();
                        if (width < 750) {
                            hideSlideOut();
                        }
                    }}
                >
                    <p className="address-text desc">Your selected clinic:</p>
                    <p className="address-text">{order.selectedVendor.address}</p>
                    <p className="address-text">
                        {order.selectedVendor.city}, {order.selectedVendor.state} {order.selectedVendor.zip}
                    </p>
                </div>
                <Route path="/clinic/:vendorId" component={KingdomList} />
                <TransitionGroup component={null} appear={true}>
                    <CSSTransition
                        key={order.kingdomSelectedFromDocument ? order.useDocumentKingdom : order.selectedKingdom}
                        classNames="fade"
                        timeout={400}
                    >
                        <Switch location={location}>
                            <Route
                                path="/clinic/:vendorId/:kingdomId"
                                render={() => (
                                    <TransitionGroup component={null} appear={false}>
                                        <CSSTransition key={order.selectedPhylum} classNames="slide" timeout={200}>
                                            <Switch location={location}>
                                                <Route
                                                    exact
                                                    path="/clinic/:vendorId/:kingdomId"
                                                    render={(props) => (
                                                        <SlidingPhylumPanel
                                                            {...props}
                                                            kingdom={order.selectedKingdom}
                                                            returnToMap={this.returnToMap}
                                                        />
                                                    )}
                                                />
                                                <Route
                                                    path="/clinic/:vendorId/:kingdomId/:phylumId"
                                                    render={(props) => (
                                                        <SlidingFamilyPanel
                                                            {...props}
                                                            kingdom={order.selectedKingdom}
                                                            phylum={order.selectedPhylum}
                                                        />
                                                    )}
                                                />
                                            </Switch>
                                        </CSSTransition>
                                    </TransitionGroup>
                                )}
                            />
                        </Switch>
                    </CSSTransition>
                </TransitionGroup>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        order: state.order,
        width: state.interface.width,
        lastFocusedVendor: state.interface.lastFocusedVendor,
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            clearOrder: clearOrder,
            useDocumentKingdom: useDocumentKingdom,
            focusTargetVendor: focusTargetVendor,
            hideSlideOut: hideSlideOut,
            setTargetVendor: setTargetVendor,
            updateHistory: updateHistory,
        },
        dispatch,
    );
};
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ServiceSelector));
