import React, { Component } from 'react';
import '../style/views/ModalDialog.css';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import ReactHtmlParser from 'react-html-parser';
import { aboutClear } from '../store/actions';

class ModalDialog extends Component {
    targetRef = React.createRef();
    targetElement = null;

    componentDidMount() {
        this.targetElement = this.targetRef.current;
        disableBodyScroll(this.targetElement);
    }

    componentWillUnmount() {
        clearAllBodyScrollLocks();
    }

    closeModal = (e) => {
        const { history } = this.props;
        e.stopPropagation();
        setTimeout(() => enableBodyScroll(this.targetElement), 700);

        aboutClear();
        history.goBack();
    };

    render() {
        const { aboutService } = this.props;
        return (
            <div className="modal-dialog" ref={this.modalElement}>
                <div className="modal-backdrop" onClick={(e) => this.closeModal(e)} />
                <div className="modal-content-container">
                    <div className="modal-close" onClick={(e) => this.closeModal(e)}>
                        Done
                    </div>
                    <div className="modal-header">{aboutService.portalServiceName}</div>
                    <hr />
                    <div className="modal-body">{ReactHtmlParser(aboutService.serviceDetails)}</div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {};
};
const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            aboutClear: aboutClear,
        },
        dispatch,
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalDialog);
